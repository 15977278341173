module.exports = {
  cantUpdateTimezoneForAllAlarms:
    'No se puede actualizar la zona horaria de las alarmas existentes',
  cantUpdateRingtonesForAllAlarms:
    'No se pueden actualizar los ajustes de sonido y vibración de las alarmas existentes.',
  preReminderWillRingAt: {
    zero: 'Sonará un recordatorio previo para esta alarma \nen {{timeString}}.',
    one: 'Un recordatorio previo para esta alarma llamará \nen {{timeString}} el día anterior.',
    other:
      'Un recordatorio previo para esta alarma llamará \nen {{timeString}} {{count}} días antes.'
  },
  verficationCodeInfo:
    'Recibirá un mensaje de texto o una llamada telefónica con un código de 4 dígitos.',
  verficationCodeInfoWhatsApp:
    'Recibirás un mensaje de WhatsApp con un código de 4 dígitos.',
  deletePastAlarmsNotificationMessage:
    'Tiene varias alarmas inactivas. Borra las alarmas que ya no necesites.',
  confirmCountryCodeAndEnterNumber:
    'Confirme el prefijo de su país e introduzca su número de teléfono.',
  sendCodeOnSms: 'Enviar código por SMS',
  sendCodeOnWhatsApp: 'Enviar código por WhatsApp',
  verifyNumberWhatsApp:
    'Enviaremos un mensaje de WhatsApp con el código de verificación a:\n\n{{formattedMobileNumber}}',
  verifyNumberCall:
    'Recibirá una llamada telefónica con el código de verificación en:\n    \n{{formattedMobileNumber}}',
  defaultTimezoneForAlarm: 'Zona horaria por defecto para las alarmas',
  changeDefaultTimezoneForAllExistingAlarms: {
    zero: '¿Desea restablecer la zona horaria de todas las alarmas existentes?',
    one: '¿Desea cambiar la zona horaria de todas las alarmas existentes a esta zona horaria? Las alarmas llamarán a la hora configurada en la nueva zona horaria.'
  },
  changeDefaultRingerSettingsForAllExistingAlarms:
    '¿Quieres cambiar los ajustes de sonido y vibración de las alarmas existentes?\n  \n  Si selecciona Sí, las alarmas existentes llamarán con los nuevos ajustes. Si selecciona No, las alarmas existentes seguirán llamando con los ajustes antiguos.',
  defaultTimezoneChangedForAllAlarms:
    'Zona horaria cambiada para todas las alarmas.',
  ringerSettingsChangedForAllAlarms:
    'Se han modificado los ajustes de sonido y vibración de las alarmas existentes.',
  unlimitedAlarmsFeatureDescription: 'Crea más alarmas de grupo y de amigo.',
  instantAlarmsPremiumFeature: 'Enviar alertas instantáneas',
  instantAlarmsFeatureDescription:
    'Envía múltiples alertas instantáneas a tus contactos y grupos.',
  duplicateAlarmPremiumFeature: 'Duplicar alarma',
  duplicateAlarmFeatureDescription:
    'Crea rápidamente copias de una alarma con los mismos ajustes y realiza los cambios antes de guardarla.',
  showingAlarmsForDate: 'Mostrar alarmas para {{date}}',
  shareAlarm: 'Compartir enlace de alarma',
  shareAlarmDescription:
    'Puedes compartir esta alarma con cualquier persona mediante un enlace sin añadirla como contacto. Cuando alguien pulse el enlace en su teléfono, se creará una copia de la alarma.',
  sendCodeAgain: 'Enviar código de nuevo',
  selectDefaultTimezoneForAlarmDefaultTooltipMessage:
    'Elija una zona horaria por defecto para sus alarmas. Puede cambiar la zona horaria de las alarmas cuando las cree.',
  alarmLinkRelativeToTimezone: '{{timeString}} en su zona horaria.',
  alarmLinkNotRelativeToTimezone:
    'La alarma de todos llamará a las {{timeString}}',
  alarmShouldRingAt: 'La alarma de todo el mundo llamará a las:',
  showHourlyAlarms: 'Mostrar alarmas horarias',
  critical: 'Crítica',
  alarmsWillBeAutomaticallyDeleted:
    'Las alarmas se eliminarán permanentemente 30 días después de su borrado.',
  unlimitedAlarmsPremiumFeature: 'Alarmas ilimitadas para grupos y amigos',
  notAcknowledged: 'No reconocido',
  closed: 'Cerrado',
  showFullScreenIntents: 'Mostrar alarma en pantalla completa',
  showFullScreenIntentsDescription:
    'La alarma aparecerá en pantalla completa cuando el teléfono esté bloqueado.',
  percentVolume: '{{volume}}% volumen',
  selectADate: 'Seleccione una fecha para ver las alarmas.',
  affiliates: 'Afiliados',
  referralCount: 'Recuento de remisiones',
  addAffiliate: 'Añadir afiliado',
  creatingAffiliate: 'Creación de afiliados',
  affiliateLink: 'Enlace de afiliación',
  upgradeRequiredForWeb: 'Actualización necesaria',
  upgradeToPremiumForWeb:
    'Esta función requiere una suscripción premium. Compra una suscripción premium desde la aplicación móvil para utilizar esta función.',
  accountType: 'Tipo de cuenta',
  profileAndSettings: 'Perfil y ajustes',
  confirmIncidents: 'Confirmar varios incidentes',
  removeIncidents: 'Eliminar varios incidentes',
  incidents: 'Incidentes',
  closeSelectedIncidents: 'Cerrar incidentes seleccionados',
  viewIncidentNotes: 'Ver notas de incidencias',
  incidentNotes: 'Notas del incidente',
  addNote: 'Añadir nota',
  noAdditionalNotesForIncident: 'No hay notas adicionales para este incidente.',
  fromIncident: 'Del incidente:',
  fromUsers: 'De los usuarios:',
  database: 'Base de datos',
  chooseFirstDayOfWeek: 'Elija el primer día de la semana',
  firstDayOfWeek: 'Primer día de la semana',
  changePlan: 'Plan de cambio',
  currentPremiumSubscriptionStatus:
    'Actualmente está suscrito al plan {{planName}}.',
  currentProSubscriptionStatus:
    'Usted es miembro de la organización {{orgName}}.',
  changeToAnnualPlan: 'Modificación del plan anual',
  unableToChangePlan:
    'No se puede cambiar al plan anual. Ponte en contacto con el servicio de asistencia técnica a través de "Ajustes > Comentarios".',
  confirmChangeToAnnualPlan: '¿Desea cambiar al plan anual?',
  changeToAnnualPlanDescription:
    'Se le cobrará {{price}} por el plan anual. Los días restantes de tu plan actual se ajustarán al plan anual.',
  alarmTimezoneChanged:
    'Algunas alarmas se actualizaron para utilizar la zona horaria del dispositivo porque la zona horaria utilizada para esas alarmas no es compatible con este dispositivo.',
  defaultTimezoneChanged:
    'La zona horaria predeterminada para las alarmas se cambia a la zona horaria del dispositivo porque la zona horaria {{defaultTimezoneForAlarm}} no es compatible con este dispositivo.',
  invalidEmail: 'Dirección de correo electrónico no válida.',
  unableToValidateEmail:
    'No se ha podido validar el correo electrónico. {{error}}',
  havingProblems: '¿Tiene problemas?',
  alertData: 'Datos de alerta',
  notificationActionBehavior: 'Comportamiento de la acción de notificación',
  foreground: 'Primer plano',
  background: 'Fondo',
  notificationActionBehaviorTooltipMessage:
    'Si se ajusta en "Primer plano", al pulsar sobre una acción de notificación se abrirá la aplicación en primer plano. Si se ajusta en "Fondo", al pulsar sobre una acción de notificación, la aplicación se mantendrá en segundo plano.',
  getCodeOnWhatsApp: 'Obtener código en WhatsApp',
  getCode: 'Obtener código',
  dontHaveWhatsApp: '¿No tienes WhatsApp?',
  unableToSendCodeViaWhatsAppTitle: 'No se puede enviar un código por WhatsApp',
  unableToSendCodeViaWhatsAppMessage:
    '{{error}}\n      \nPor favor, solicite el código de nuevo en un canal diferente.',
  dataSecurity: 'Seguridad de los datos',
  alarms: 'Alarmas',
  contacts: 'Contactos',
  groups: 'Grupos',
  settings: 'Ajustes',
  notificationsTab: 'Notificaciones',
  specifyTimeInFutureForAlarm: 'La alarma no puede estar en el pasado.',
  specifyTimeInFutureForPreReminder:
    'El recordatorio previo de alarma está en el pasado.',
  specifyNameForGroup: 'El nombre del grupo es obligatorio.',
  updatingGroupName: 'Actualización de la información del grupo.',
  unableToEditGroupName:
    'No se ha podido actualizar la información del grupo. Por favor, inténtelo de nuevo después de algunas horas.',
  selectSmallerImage:
    'La imagen ocupa más de 10 MB. Seleccione una imagen más pequeña.',
  sendingVerificationCode:
    'Envío del código de verificación a {{formattedMobileNumber}}.',
  verifyingCode: 'Verificación del código.',
  creatingUserProfile: 'Ajuste...',
  unabeToCreateUserProfile:
    'No se puede ajustar una cuenta en esta hora. Por favor, inténtelo de nuevo en unos momentos.',
  unableToSendVerificationCode:
    'No se puede enviar el código de verificación. {{error}}',
  unableToVerifyCode: 'No se puede verificar el código. {{error}}',
  unableToRegisterUserWithoutMobileNumber:
    'No se puede realizar la configuración. {{error}}',
  permissionDeniedToAccessCameraIos:
    'Galarm no tiene permiso para acceder a la cámara. \n\nVe a Ajustes > Galarm y permite el acceso a la cámara.',
  permissionDeniedToAccessCameraAndroid:
    'Galarm no tiene permiso para acceder a la cámara.\n\nVe a Ajustes > Aplicaciones > Galarm > Permisos y permite el acceso a la cámara.',
  permissionDeniedToAccessPhotosIos:
    'Galarm no tiene permiso para acceder a las fotos.\n\nVe a Ajustes > Galarm y permite el acceso a las fotos.',
  permissionDeniedToAccessPhotosAndroid:
    'Galarm no tiene permiso para acceder a las fotos.\n\nVe a Ajustes > Aplicaciones > Galarm > Permisos y permite el acceso al almacenamiento.',
  permissionDeniedToAccessCalendarIos:
    'Galarm no tiene permiso para acceder al calendario.\nVe a Ajustes > Calendarios y permite el acceso a Galarm.',
  permissionDeniedToAccessCalendarAndroid:
    'Galarm no tiene permiso para acceder al calendario.\nVe a Ajustes > Aplicaciones > Galarm > Permisos y permite el acceso al Calendario.',
  verificationCodeSent:
    'Código de verificación enviado a {{formattedMobileNumber}}.',
  rateTheApp:
    'Esperamos que Galarm te resulte útil. Tómate un momento para puntuarnos en Play Store. Gracias por su apoyo.',
  backgroundAppRefreshDisabledPermission:
    'Es posible que las alarmas no llamen porque Galarm no tiene permiso para actualizar la aplicación en segundo plano.\n\nPulse "Activar" y active "Actualización de aplicaciones en segundo plano".',
  selectedGroupUnselected:
    'Puede seleccionar un grupo o un conjunto de contactos como participantes. El grupo seleccionado anteriormente se deseleccionará. ¿Continuar?',
  selectedContactsUnselected:
    'Puede seleccionar un grupo o un conjunto de contactos como participantes. Los contactos seleccionados anteriormente no se seleccionarán. ¿Continuar?',
  notificationsDisabledIos:
    'Las alarmas no llamarán porque Galarm no tiene permiso para mostrar notificaciones.\n\nVaya a Ajustes > Galarm > Notificaciones y active "Alertas", "Sonidos" y "Banners".',
  notificationsDisabledAndroid:
    'Las notificaciones de alarma no aparecerán en la pantalla.\n\nPulse "Activar", seleccione "Notificaciones" y permita las notificaciones.',
  alarmNotificationChannelDisabled:
    'Las notificaciones de alarma no aparecerán en la pantalla. \n\nPulse "Activar" para que las notificaciones de alarma aparezcan en la pantalla.',
  alarmLockScreenNotificationlDisabled:
    'Las notificaciones de alarma no se mostrarán en la pantalla de bloqueo. \n\nPulsa "Activar" para que las notificaciones de alarma se muestren en la pantalla de bloqueo.',
  alarmWillNotRingDueToNotificationsDisabledIos:
    'Las alarmas no llamarán porque las notificaciones están desactivadas.\n\nVe a Ajustes > Galarm > Notificaciones y activa "Alertas", "Sonidos" y "Banners".',
  alarmWillNotRingDueToCriticalAlertsDisabledIos:
    'Las alarmas no llamarán cuando el teléfono esté silenciado porque las alertas críticas están desactivadas.\n\nVaya a Ajustes > Galarm > Notificaciones y active "Alertas críticas".',
  alarmWillNotRingDueToNotificationsDisabledAndroid:
    'La alarma no aparecerá en la pantalla porque las notificaciones están desactivadas.\n\nToca "Activar", selecciona "Notificaciones" y permite las notificaciones.',
  noNotificationsScheduledAlarmInPast:
    'La alarma está en el pasado. No hay notificaciones programadas.',
  alarmOverAllowedLimit: {
    zero: '\nHa alcanzado su límite de alarmas de {{limit}} alarms.\n\nPor favor, borra o reutiliza una alarma existente.',
    one: 'Ha alcanzado su límite de alarmas de {{limit}} alarms.\n\nTienes 1 alarma inactiva que puedes borrar o reutilizar.',
    other:
      'Ha alcanzado su límite de alarmas de {{limit}} alarms.\n\nTienes {{count}} alarmas inactivas que puedes borrar o reutilizar.'
  },
  alarmAlreadyConfirmed: 'Ya lo ha confirmado.',
  alarmAlreadyDeclined: 'Ya has Rechazado.',
  participantMovedToPosition: '{{name}} se trasladó a la posición {{count}}.',
  memberIsFirstParticpant: '{{name}} es el primer participante.',
  memberIsLastParticpant: '{{name}} es el último participante.',
  cantContinueWithRegistrationWhenOffline:
    'No se puede continuar con el registro porque el dispositivo está desconectado.',
  cantContinueWithRegistrationWhenOfflineWithTryAgain:
    'No se puede continuar con el registro porque el dispositivo está desconectado. Asegúrate de que estás conectado a Internet.',
  confirmBlockContact: 'Dejará de recibir alarmas de {{name}}. ¿Continuar?',
  confirmUnblockContact:
    'Al desbloquear {{name}} también se restablecerán las alarmas bloqueadas de los mismos. ¿Proceder?',
  unblockingContact:
    'Desbloqueo de {{name}} y restablecimiento de las alarmas.',
  unableToUnblockContact: 'No se puede desbloquear el contacto. {{error}}',
  blockingContact: 'Bloqueo {{name}}.',
  unableToBlockContact: 'No se puede bloquear el contacto. {{error}}',
  cantAddContactToAlarmBecauseBlocked:
    'Ha bloqueado {{name}}. Desbloquéalos para añadirlos como participantes de la alarma.',
  cantAddContactToGroupBecauseBlocked:
    'Has bloqueado {{name}}. Desbloquéalos para añadirlos como miembros del grupo.',
  sendMessageNotAllowedForPastAlarms:
    'No se pueden enviar mensajes para alarmas inactivas.',
  nameIsRequired: 'El nombre es obligatorio.',
  confirmDisableGroupAlarm:
    'Si desactiva la alarma de grupo, también se desactivará la alarma de los demás participantes. ¿Cómo proceder?',
  removeMemberFromGroup: '¿Quieres eliminar {{name}} de este grupo?',
  leaveGroupConfirm: '¿Quieres dejar este grupo?',
  deviceOffline:
    'El dispositivo está desconectado. Asegúrese de que está conectado a Internet y vuelva a intentarlo.',
  cantUpdateGroupDefaulConfigWhenOffline:
    'La configuración de grupo modificada no se aplicará a futuras alarmas porque el dispositivo está desconectado.',
  specifyFeedback: 'Se requieren comentarios.',
  specifyEmailId: 'La dirección de correo electrónico es obligatoria.',
  specifyValidEmailId: 'La dirección de correo electrónico no es válida.',
  specifyProblem: 'Es necesario describir el problema.',
  appInstalledUsingSameNumberOnDifferentPhone:
    'Has instalado Galarm en un dispositivo diferente utilizando el mismo número. La aplicación en este dispositivo se restablece.',
  invalidUid:
    'Esta cuenta de usuario ya no existe. La aplicación Galarm de este dispositivo se ha restablecido.',
  accountDeleted: 'Tu cuenta ha sido eliminada.',
  deleteAccount: 'Eliminar mi cuenta',
  deletingAccount: 'Eliminar su cuenta',
  howCanWeImprove: 'Lamentamos su marcha. ¿Cómo podemos mejorar?',
  noMusicAlert: 'No se han encontrado archivos de música.',
  noLongerAvailableMusic:
    '{{fileName}} ya no está disponible. El tono de llamada se ha cambiado a {{defaultRingtoneName}}',
  userInactiveTitle: 'Bienvenido de nuevo.',
  userInactiveMessage:
    'Crea alarmas para las cosas importantes y no te las pierdas nunca.',
  createAlarm: 'Crear alarma',
  updatedAppAvailable: 'Ya está disponible una nueva versión de la aplicación.',
  alarmOccurrenceDisabled: 'Desactivado para este suceso',
  ownAlarmOccurrenceDone: {
    zero: 'Marcado Hecho en {{timeString}}',
    one: 'Hecho en {{timeString}} el {{dateString}}'
  },
  ownAlarmOccurrenceSkipped: {
    zero: 'Saltar en {{timeString}}',
    one: 'Saltar en {{timeString}} en {{dateString}}'
  },
  enabledAlarmsCount: {
    one: '1 alarma activada.',
    other: '{{count}} alarmas activadas.'
  },
  enabledAndNotEnabledAlarmsCount: {
    one: '1 alarma activada. Las alarmas sin ocurrencias futuras permanecen desactivadas.',
    other:
      '{{count}} alarmas activadas. Las alarmas sin ocurrencias futuras permanecen desactivadas.'
  },
  disabledAlarmsCount: {
    one: '1 alarma desactivada.',
    other: '{{count}} alarmas desactivadas.'
  },
  ownAlarmOccurrenceUnacknowledged: 'Sin respuesta',
  participantAlarmOccurrenceDone: {
    zero: 'Hecho por {{participantName}} en {{timeString}}',
    one: 'Hecho por {{participantName}} en {{timeString}} el {{dateString}}'
  },
  participantAlarmOccurrenceSkipped: {
    zero: 'Saltar por {{participantName}} en {{timeString}}',
    one: 'Saltar por {{participantName}} en {{timeString}} en {{dateString}}'
  },
  participantAlarmOccurrenceUnacknowledged: '{{participantName}} no respondió',
  groupAlarmOccurrenceConfirmed: {
    zero: 'Confirmado en {{timeString}}',
    one: 'Confirmado en {{timeString}} el {{dateString}}'
  },
  groupAlarmOccurrenceDeclined: {
    zero: 'Rechazada en {{timeString}}',
    one: 'Rechazada en {{timeString}} el {{dateString}}'
  },
  warning: 'Advertencia',
  info: 'Información',
  confirm: 'Confirmar',
  error: 'Error',
  cantUploadImage: 'No se puede cargar la imagen',
  cantDeleteImage: 'No se puede borrar la imagen',
  cantEditGroup: 'No se puede editar el grupo',
  cantAddNewContact: 'No puedo añadir un nuevo contacto',
  cantDeleteContact: 'No se puede eliminar un contacto',
  cantEditContact: 'No se puede editar el contacto',
  cantSaveAlarm: 'No se puede guardar la alarma',
  cantSaveGroup: 'No se puede guardar el grupo',
  cantReloadContacts: 'No se pueden recargar los contactos',
  cantTakePhoto: 'No puedo hacer fotos',
  cantExportToCalendar: 'No se puede exportar al calendario',
  cantOpenPhotoLibrary: 'No se puede abrir la fototeca',
  cantDeleteAlarmHistory: 'No se puede borrar el historial de alarmas',
  cannotDuplicateAlarm: 'No se puede duplicar la alarma',
  upgradeToPremiumToDuplicateAlarm:
    'Actualización a Premium para crear más alarmas de grupo y amigo.',
  confirmAcceptAlarm: '¿Aceptar alarma?',
  confirmRejectAlarm: '¿Rechazar la alarma?',
  deleteAlarm: '¿Borrar alarma?',
  restoreAlarmConfirmation: '¿Restaurar alarma?',
  respondToRepeatingAlarm: {
    zero: 'Usted es añadido como destinatario a la alarma {{alarmName}} por {{alarmCreatorName}}. Recibirá una alerta en {{alarmTimeString}} {{alarmRepetitionString}} a partir de {{alarmDateString}}',
    one: 'Se le añade como participante a la alarma {{alarmName}} por {{alarmCreatorName}}. Recibirá la alarma en {{alarmTimeString}} {{alarmRepetitionString}} a partir de {{alarmDateString}}'
  },
  respondToOneTimeAlarm: {
    zero: 'Usted es añadido como destinatario a la alarma {{alarmName}} por {{alarmCreatorName}}. Recibirá una alerta en {{alarmTimeString}} {{alarmDateString}}',
    one: 'Se le añade como participante a la alarma {{alarmName}} por {{alarmCreatorName}}. Recibirá una alerta en {{alarmTimeString}} {{alarmDateString}}'
  },
  cantMoveMemberUp: 'No se puede mover {{name}} Up',
  cantMoveMemberDown: 'No se puede mover {{name}} Abajo',
  decline: 'Rechazar',
  cantEditProfile: 'No se puede editar el perfil',
  cantSelectContact: 'No se puede seleccionar contacto',
  cantSendMessage: 'No se puede enviar un mensaje',
  cantSendVerificationCode: 'No se puede enviar el código de verificación',
  confirmBlockContactTitle: 'Confirmar contacto en bloque',
  confirmUnblockContactTitle: 'Confirmar Desbloquear contacto',
  cantSaveProfile: 'No se puede guardar el perfil',
  cantBlockContact: 'No se puede bloquear el contacto',
  cantUnblockContact: 'No se puede desbloquear el contacto',
  cantLoadEarlierMessages: 'No se pueden cargar los mensajes anteriores',
  cantRestoreAlarm: 'No se puede restablecer la alarma',
  cantSendFeedback: 'No puedo enviar comentarios',
  cantSubmitProblem: 'Problema de imposibilidad de envío',
  cantDeletePastAlarmsInBulk:
    'No se pueden borrar alarmas inactivas de forma masiva',
  cantAddNewGroup: 'No se puede añadir un nuevo grupo',
  alreadySnoozing: 'Ya está Posponer',
  markAlarmUndone:
    'Has marcado esta alarma como "Hecho" {{durationString}} ago. ¿Deshacer?',
  markAlarmUnskipped:
    'Te saltaste esta alarma hace {{durationString}}. ¿Deshacer?',
  cantUndo: 'No se puede deshacer',
  fetchMusicFromPhone: 'Encontrar música disponible.',
  enableAllAlarmsInCategory: '¿Habilitar todas las alarmas de esta categoría?',
  disableAllAlarmsInCategory:
    '¿Desactivar todas las alarmas de esta categoría?',
  allAlarmsAlreadyEnabled:
    'Todas las alarmas de esta categoría ya están activadas.',
  allAlarmsAlreadyDisabled:
    'Todas las alarmas de esta categoría ya están desactivadas.',
  time: 'Hora',
  timeForBuddy: 'Hora de Buddy',
  timeForYou: 'Recordatorio en',
  name: 'Nombre',
  title: 'Título',
  notes: 'Notas',
  repeat: 'Repetir',
  preReminder: 'Recordatorio previo',
  remove: 'Eliminar',
  preReminderShortAlert: '{{duration}} Recordatorio previo',
  preReminderTitle: '{{alarmName}} en {{date}}',
  preReminderGroupHelperText:
    'Este recordatorio previo es sólo para usted. Los demás participantes en la alarma pueden ajustar sus propios recordatorios previos cuando reciban la alarma.',
  addPreReminder: 'Añadir recordatorio previo',
  helperTextForPreReminder:
    'Pulse el botón de reinicio situado en la parte superior para eliminar el recordatorio previo.',
  edit: 'Editar',
  delete: 'Borrar',
  creator: 'Creador',
  useAsDefaultGroupConfiguration: 'Aplicar a futuras alarmas',
  done: 'Hecho',
  skipped: 'Saltar',
  save: 'Guardar',
  members: 'Miembros',
  mobileNumber: 'Número de teléfono',
  enter: 'Entre en',
  addMembers: 'Añadir miembros',
  respond: 'Responder',
  yes: 'Sí',
  no: 'No',
  cancel: 'Cancelar',
  participants: 'Participantes',
  takePhoto: 'Tomar foto',
  chooseFromLibrary: 'Elija de la biblioteca',
  resendCode: 'Reenviar código',
  register: 'Regístrese en',
  inviteFriends: 'Compartir esta aplicación',
  invite: 'Invite a',
  incorrectNumber: '¿Número incorrecto?',
  date: 'Fecha',
  cascadingAlarmInterval: 'Hora entre alertas de participantes',
  ringParticipantAlarmsByDefault: 'Llamar a las alarmas sin aceptarlas',
  alarmRingtone: 'Tono de llamada de alarma',
  pickASong: 'Escoge música del teléfono',
  privacyPolicy: 'Política de privacidad',
  sendFeedback: 'Comentarios',
  galarmWeb: 'Galarm Web',
  galarmEnterprise: 'Galarm Pro',
  galarmAdmin: 'Galarm Admin',
  troubleshooting: 'Solución de problemas',
  alarmDidntRingHelp: '¡Ayuda! Mi alarma no ha llamado',
  autoStartSettingHelperText:
    'Si tu teléfono tiene ajuste de inicio automático, asegúrate de que esté activado para Galarm.',
  batteryOptimizationHelperText:
    'Desactivar la optimización de la batería para Galarm',
  troubleshootingHelperText:
    'Es posible que los siguientes ajustes impidan que las alarmas llamen como es debido. Revise estos ajustes.',
  getMoreHelpText: '¿Sigues teniendo problemas?',
  autoStartSetting: 'Arranque automático',
  batteryOptimization: 'Optimización de la batería',
  battery: 'Batería',
  rateTheAppSettings: 'Valora esta aplicación',
  about: 'Acerca de & Ayuda',
  leaveGroup: 'Dejar grupo',
  removeGroup: 'Eliminar grupo',
  viewMember: 'Ver {{memberName}}',
  removeMember: 'Eliminar {{memberName}}',
  changeMemberState: 'Cambiar quién puede alertar {{memberName}}',
  changeYourGroupState: 'Cambia quién puede avisarte',
  numMembersInGroup: 'Miembros: {{count}}',
  notifications: 'Las notificaciones de la aplicación están desactivadas',
  backgroundAppRefresh:
    'La actualización en segundo plano de la aplicación está desactivada',
  groupsInCommon: 'Grupos en común',
  alarmsInCommon: 'Alarmas comunes',
  close: 'Cerrar',
  responsePending: '¿Su respuesta?',
  later: 'Más tarde',
  install: 'Instale',
  all: 'Todos',
  numYears: { one: '1 año', other: '{{count}} años' },
  numMonths: { one: '1 mes', other: '{{count}} meses' },
  numDays: { one: '1 día', other: '{{count}} días' },
  hours: { one: '1 hora', other: '{{count}} horas' },
  minutes: { one: '1 minuto', other: '{{count}} minutos' },
  seconds: { one: '1 segundo', other: '{{count}} segundos' },
  minutesSmall: '{{count}} min',
  secondsSmall: '{{count}} sec',
  hoursAfter: { one: '1 hora después', other: '{{count}} horas después de' },
  minutesAfter: {
    one: '1 minuto después',
    other: '{{count}} minutos después de'
  },
  hoursAfterBuddy: {
    one: '1 hora después amigo',
    other: '{{count}} horas después de amigo'
  },
  minutesAfterBuddy: {
    one: '1 minuto después de amigo',
    other: '{{count}} minutos después amigo'
  },
  longerThanAnHour: '> 1 hora',
  licenses: 'Licencias de terceros',
  version: 'Versión',
  termsOfService: 'Condiciones de uso',
  showPrivacyPolicy: '> Política de privacidad',
  showTermsOfService: '> Condiciones de servicio',
  showLicenses: '> Licencias de terceros',
  change: 'Cambia',
  snooze: 'Posponer',
  ringOnVibrate: 'Llamar en silencio',
  vibrate: 'Vibrar',
  ringOnEarphoneOnly: 'Alarma de llamada sólo con auriculares',
  ringOnSpeakerAndEarphone: 'Alarma de llamada en altavoz y auriculares',
  whenEarphoneIsConnected: 'Cuando los auriculares están conectados',
  tapGesture: 'Toque',
  slideGesture: 'Diapositiva',
  silent: 'Silencioso',
  volume: 'Volumen',
  soundAndVibration: 'Sonido y vibración',
  advancedSettings: 'Ajustes avanzados',
  gestureToDismissAnAlarm: 'Gesto para descartar una alarma',
  someNotificationsAreMuted: 'Algunas notificaciones están silenciadas',
  allNotificationsEnabled: 'Todas las notificaciones están activadas',
  notificationSettings: 'Ajustes de notificación',
  galarmWebNotifications: 'Galarm Web Notificaciones',
  alarmsfromOthersNotifications:
    'Notificaciones de alarmas a los participantes',
  sharedAlarmNotifications: 'Notificaciones de alarma compartidas',
  alarmChatNotifictions: 'Notificaciones de chat de alarma',
  groupNotifications: 'Notificaciones de grupo',
  alarmAcknowledgementNotifications: 'Notificaciones de confirmación de alarma',
  send: 'Enviar',
  prev: 'Anterior',
  snoozing: '{{timeString}}',
  specifySnoozeDuartion: 'Especifica la duración del Posponer',
  chooseDifferentDuration: 'Elija otra duración',
  noCalendarsFound: 'No se han encontrado calendarios en el dispositivo.',
  eventWillEndAt: 'El acto finalizará en {{timeString}}.',
  selectDurationWithinTheSameDay:
    'El evento no puede cruzar la frontera del día.',
  cannotMapRepeatTypeInTheDeviceCalendar:
    'No se puede "Exportar al calendario" porque el ajuste "Repetir" de la alarma no es compatible con el calendario.',
  alarmSavedToCalendar: 'La alarma se ha guardado correctamente en la agenda.',
  faq: 'Preguntas frecuentes',
  openSettings: 'Abrir ajustes',
  openAppSettings: 'Abrir los ajustes de la aplicación',
  alreadyDone: 'Hecho',
  enableAutoStart: 'Activar arranque automático',
  restrictedApps: 'Aplicaciones restringidas',
  unmonitoredApps: 'Aplicaciones no supervisadas',
  enableProtected: 'Aplicaciones protegidas',
  manageAppLaunch: 'Gestionar el lanzamiento de aplicaciones',
  unblockContact: 'Desbloquear contacto',
  blockContact: 'Bloquear contacto',
  call: 'Llame a {{name}}',
  text: 'Texto {{name}}',
  chat: 'Chat',
  ok: 'OK',
  stopSnooze: 'Detener Posponer',
  emailId: 'Su dirección de correo electrónico',
  feedback: 'Comentarios',
  submit: 'Enviar',
  reportAProblem: 'Informar de un problema',
  enableAlarm: 'Activar alarma',
  restoreAlarm: 'Restablecer alarma',
  deletePastAlarms: 'Borrar alarmas inactivas',
  accept: 'Aceptar',
  defaultPersonalAlarmName: 'Recordatorio personal',
  defaultGroupAlarmName: 'Evento de grupo',
  defaultRecipientAlarmName: 'Alarma para amigos',
  search: 'Buscar en',
  recent: 'Recientes',
  upcoming: 'Próximamente',
  missed: 'Falta',
  expired: 'Caducado',
  past: 'Anterior',
  active: 'Activo',
  inactive: 'Inactivo',
  allAlarms: 'Todas las alarmas',
  myAlarms: 'Mis alarmas',
  participantAlarms: 'Alarmas para participantes',
  pendingParticipantAlarms: 'Nuevas alarmas de otros',
  alarmsWithUnreadChatMessages: 'Alarmas con nuevo chat',
  systemNotifications: 'Mensajes del sistema',
  missedAlarms: 'Alarmas perdidas',
  recentlyDeletedAlarms: 'Alarmas borradas recientemente',
  searchAlarms: 'Alarmas de búsqueda',
  participant: 'Participante',
  you: 'Usted',
  none: 'Ninguno',
  onceOnly: 'Sólo una vez',
  weekdays: 'Días laborables',
  weekends: 'Fines de semana',
  everyDayString: 'Diario',
  everySunday: 'Todos los Domingos',
  everyMonday: 'Todos los Lunes',
  everyTuesday: 'Todos los Martes',
  everyWednesday: 'Todos los Miércoles',
  everyThursday: 'Todos los Jueves',
  everyFriday: 'Todos los Viernes',
  everySaturday: 'Todos los Sábados',
  sunday: 'Domingo',
  monday: 'Lunes',
  tuesday: 'Martes',
  wednesday: 'Miércoles',
  thursday: 'Jueves',
  friday: 'Viernes',
  saturday: 'Sábado',
  january: 'Enero',
  february: 'Febrero',
  march: 'Marzo',
  april: 'Abril',
  may: 'Mayo',
  june: 'Junio',
  july: 'Julio',
  august: 'Agosto',
  september: 'Septiembre',
  october: 'Octubre',
  november: 'Noviembre',
  december: 'Diciembre',
  jan: 'Ene',
  feb: 'Feb',
  mar: 'Mar',
  apr: 'Abr',
  shortMay: 'May',
  jun: 'Jun',
  jul: 'Jul',
  aug: 'Ago',
  sep: 'Sep',
  oct: 'Oct',
  nov: 'Nov',
  dec: 'Dic',
  sun: 'Dom',
  mon: 'Lun',
  tue: 'Mar',
  wed: 'Mié',
  thu: 'Jue',
  fri: 'Vie',
  sat: 'Sáb',
  first: '1ª',
  second: '2ª',
  third: '3ª',
  fourth: '4ª',
  fifth: '5ª',
  every: 'Cada',
  oddNumberedDays: 'Fechas impares',
  evenNumberedDays: 'Fechas pares',
  noParticipants: 'No participantes',
  typeYourMessage: 'Escriba su mensaje',
  cantPostMessageToPastAlarm:
    'No se puede enviar un mensaje a una alarma inactiva',
  groupName: 'Grupo: {{name}}',
  alarm: 'Alarma',
  confirmed: 'Confirmado',
  declined: 'Rechazado',
  Done: 'Hecho',
  Skip: 'Saltar',
  Confirm: 'Confirmar',
  Decline: 'Rechazar',
  Accept: 'Aceptar',
  today: 'hoy',
  on: 'en',
  recipientAlarm: 'Alarma para amigos',
  recipient: 'Destinatario',
  remindMeAfter: 'Recordatorio',
  remindMeIn: 'Recordatorio en',
  newRecipientAlarm: 'Nueva alarma para amigos',
  editRecipientAlarm: 'Editar alarma de amigo',
  newInstantAlarm: 'Nueva alarma instantánea',
  instantAlarms: 'Alarmas instantáneas',
  alarmDone: 'Alarma Marcada Hecho',
  alarmSkipped: 'Alarma Saltada',
  undo: 'Deshacer',
  enterVerificationCode:
    'Introduzca el código de 4 dígitos que aparece a continuación:',
  initializing: 'Inicialización de',
  tryAgain: 'Inténtalo de nuevo',
  nMore: '+ {{count}} más',
  enableNotification: 'Activar',
  enableAlarmNotificationChannel: 'Activar',
  enableBackgroundAppRefresh: 'Activar',
  viewImpactedAlarms: 'Alarmas',
  viewImpactedGroups: 'Grupos',
  dismiss: 'Descartar',
  minsInterval: '+ {{count}}m',
  hoursInterval: '+ {{count}}h',
  daysInterval: '+ {{count}}d',
  snoozeUnitHours: 'Horas',
  snoozeUnitMinutes: 'Minutos',
  snoozeUnitDays: 'Días',
  selectMinutes: 'Seleccionar minutos',
  selectHours: 'Seleccionar horas',
  selectDays: 'Seleccionar días',
  selectHoursAndMinutes: 'Seleccionar horas y minutos',
  enterDuration: 'Duración',
  snoozeUnit: 'Unidad',
  specifySnoozeUnit: 'Especificar unidad de Posponer',
  specifySnoozeDuration: 'Especifica la duración del Posponer',
  duration: 'Duración',
  quickAlarmName: {
    zero: '{{minutes}} min recordatorio',
    one: '{{hours}} hora recordatorio',
    other: '{{hours}} hora {{minutes}} min recordatorio'
  },
  alarmTitleForGoogleVoiceTimerCommand: '{{durationString}} recordatorio',
  addedAlarmFromVoiceCommand:
    'Se ha añadido una alarma para {{alarmName}} en {{dateString}}',
  addedTimerFromVoiceCommand: 'Añadido un temporizador para {{duration}}',
  clear: 'Claro',
  ringtoneDuration: 'Duración del tono de llamada',
  chooseDuration: 'Pulse una duración. Puede pulsar varias veces.',
  newAlarm: 'Nueva alarma',
  quickAlarm: 'Recordatorio rápido',
  quickAlarmWillRingAt: 'La alarma llamará a {{timeString}}.',
  random: 'Al azar',
  enableAllAlarms: 'Activar todas las alarmas',
  disableAllAlarms: 'Desactivar todas las alarmas',
  deleteAllAlarms: 'Borrar todas las alarmas',
  hideCategory: 'Ocultar categoría',
  unhideCategory: 'Desocultar categoría',
  dontShowHiddenCategories: 'No mostrar categorías ocultas',
  showHiddenCategories: 'Mostrar categorías ocultas',
  welcomeAlarmsTitle: 'Empecemos.',
  welcomeAlarmsMessage: 'Estas son algunas de las alarmas más utilizadas:',
  onboardingAlarms: 'Alarmas de incorporación',
  hiddenAlarmCategories: 'Categorías ocultas',
  restorePurchases: 'Restaurar compras',
  noAvailablePurchasesFound:
    'No se han encontrado compras disponibles para su cuenta.\n\nEnvíanos un comentario a través de "Ajustes > Comentarios" si tienes una compra válida que no puedes restaurar.',
  restoringPurchases: 'Restablecer las compras',
  fetchingContacts: 'Comprobación de los contactos que utilizan Galarm',
  enterNameAndUploadPhotoForUser:
    'Introduzca su nombre a continuación y, si lo desea, suba su foto de perfil',
  enterNameAndUploadPhotoForGroup:
    'Introduzca un nombre a continuación y, si lo desea, suba una foto de grupo',
  noContactsWithAppInstalled: 'Nadie de tus contactos utiliza Galarm.',
  galarmContacts: {
    one: '1 contacto Galarm',
    other: '{{count}} Contactos de Galarm'
  },
  contactsPendingInvitation: {
    one: '1 invitación pendiente',
    other: '{{count}} invitaciones pendientes'
  },
  otherContacts: { one: '1 otro contacto', other: '{{count}} otros contactos' },
  over100Contacts: 'Más de 100 contactos',
  noAlarms: 'No tienes ninguna alarma. Pulse + para añadir una nueva alarma.',
  noContactsWeb: {
    one: 'No hemos encontrado ningún contacto que utilice Galarm. O bien no tenemos permiso para acceder a tus contactos o bien nadie de tus contactos utiliza Galarm. Puedes sincronizar tus contactos desde la aplicación móvil.\n\nPuedes añadir contactos que utilicen Galarm a tus alarmas de diferentes maneras para beneficiarte de la aplicación',
    other:
      'Regístrese en la aplicación móvil para empezar a añadir contactos a sus alarmas.'
  },
  viewingAlarmsInCommon: 'Alarmas comunes con {{name}}',
  viewingGroupsInCommon: 'Grupos en común con {{contactName}}',
  changeGroupMemberConfigTextForCascadingAlarm:
    'Se avisará a los participantes activos en el orden indicado.',
  changeGroupMemberConfigTextForSimultaneousAlarm:
    'Sólo se avisará a los participantes activos.',
  changeGroupMemberStateText: {
    zero: 'Cambia los miembros que pueden avisarte cuando se crea una alarma para el grupo "{{groupName}}".\n\nSólo se le avisará si la alarma ha sido creada por miembros autorizados.',
    one: 'Cambiar los miembros que pueden alertar a {{memberName}} cuando se crea una alarma para el grupo "{{groupName}}".\n\n{{memberName}} será alertado sólo si la alarma es creada por miembros permitidos.'
  },
  allowed: 'Permitido',
  notAllowed: 'No permitido',
  changeStatus: 'Miembros permitidos',
  noUpcomingAlarms: 'No hay alarmas',
  showMore: 'Ver más',
  nMorePastAlarms: {
    one: '1 alarma inactiva más',
    other: '{{count}} más alarmas inactivas'
  },
  shareAppMessage:
    'Hago todas mis cosas a tiempo creando recordatorios para ellas con la aplicación Galarm. A través de esta app, también podemos incluirnos mutuamente en nuestros recordatorios. Recomiendo descargar esta innovadora aplicación de alarmas y recordatorios. {{downloadLink}}',
  addingMembers: 'Añadir miembros.',
  unableToAddMembers: 'No se pueden añadir miembros. {{error}}',
  removingMember: 'Baja de miembro.',
  unableToRemoveMember: 'No se puede eliminar un miembro. {{error}}',
  leavingGroup: 'Dejando el grupo.',
  unableToLeaveGroup: 'No se puede salir del grupo. {{error}}',
  expiredPersonalAlarmForCreator: {
    zero: 'Esta alarma expiró hace {{durationString}}. Toca "Hecho" para completar la alarma.',
    one: 'Esta alarma expiró hace {{durationString}}. Toca "Hecho" para completar la alarma y notificárselo a los participantes.',
    other:
      'Esta alarma expiró hace {{durationString}}. Toca "Hecho" para completar la alarma y notificárselo a los participantes.'
  },
  donePersonalAlarmForCreator:
    'Esta alarma expiró hace {{durationString}}. La alarma está marcada como Hecho.',
  skippedPersonalAlarmForCreator:
    'Esta alarma expiró hace {{durationString}}. Ha saltado la alarma.',
  expiredGroupAlarm:
    'Esta alarma caducó hace {{durationString}}. Confirme su participación.',
  expiredPersonalAlarmForParticipant:
    'Esta alarma caducó hace {{durationString}}. Por favor, recuérdelo a {{creatorName}}.',
  alarmDurationAndroid:
    'Alarma ajustada para {{durationString}} a partir de ahora.',
  alarmDurationIos:
    'Alarma ajustada para {{durationString}} a partir de ahora.',
  alarmFeedbackHeader:
    'Se enviará parte de la información de esta alarma con fines de depuración. No se compartirá ningún dato personal como el nombre de la alarma, notas o participantes.',
  privacyPolicyHeader:
    'El respeto a tu privacidad marca constantemente nuestros relojes. Al igual que tú, valoramos la privacidad, por lo que queremos que sepas que no compartimos tu información personal con nadie, a menos que sea necesario para instalar la aplicación o cuando lo exija la ley. En todos los casos, nunca vendemos tu información. Así de sencillo.\n \nLas alarmas que creas sólo se pueden ver en tu dispositivo y, si una alarma incluye a otros participantes, en los dispositivos de esos participantes.\n \nAccedemos a la libreta de direcciones/lista de contactos de tu teléfono sólo para rellenar la pestaña de contactos en Galarm. No almacenamos esa lista para ningún otro fin. Es un honor que sea tu libreta de direcciones.\n \nAccedemos a tu cámara y a tus fotos sólo para que puedas ajustar una buena foto de perfil. Esta foto aparecerá como tu avatar o como foto de perfil de un grupo que edites. Otros usuarios de Galarm que sean miembros de las alarmas que hayas ajustado podrán ver esta foto.\n\nEnviamos la IP pública de su dispositivo y su ID único con todas las solicitudes, por ejemplo, al solicitar y verificar el código durante el proceso de registro. Esta información no está vinculada a su cuenta y sólo se utiliza para determinar cualquier posible actividad maliciosa.\n \nConsulte la política de privacidad completa para obtener más detalles sobre la información que recopilamos y cómo la utilizamos.',
  termsOfServiceHeader:
    'Al utilizar la aplicación Galarm, usted acepta nuestras Condiciones de servicio, que rigen su acceso y uso de esta aplicación móvil y las funciones y servicios que ofrece. \n\nEl lema de Galarm es sencillo: ¡ayudarle a no perderse ningún evento!  Tanto si se trata de configurar alarmas personales para despertarse, llegar a tiempo a las citas y establecer recordatorios para actividades como tomar la medicación a tiempo, como de crear alarmas de grupo para organizar actividades en grupo como salidas al cine, eventos deportivos y fiestas, Galarm le permite disponer de un despertador social para recordarse unos a otros, seguir el estado de los demás y comunicarse.\n\nPor favor, utiliza Galarm sólo si estás de acuerdo con estos términos. A continuación encontrarás un enlace a las mismas.',
  acknowledgedPersonalAlarm:
    'Usted marcó la alarma "Hecho" hace {{durationString}}.',
  skippedPersonalAlarm: 'Te saltaste la alarma hace {{durationString}}.',
  authenticatingWithServer: 'Conectando...',
  selectParticipantsForPersonalAlarm:
    'Selecciona un grupo o un conjunto de personas que actúen como apoyo para tu alarma personal.',
  selectParticipantsForGroupAlarm:
    'Selecciona un grupo o un conjunto de personas para tu actividad de grupo.',
  acknowledgedGroupAlarm:
    'Usted {{acknowledgedAsString}} esta alarma {{durationString}} hace.',
  acknowledgedExpiredGroupAlarm:
    'Esta alarma expiró hace {{durationString}}. Usted {{acknowledgedAsString}} esta alarma.',
  acknowledgedPersonalAlarmForParticipant:
    '{{creatorName}} marcó la alarma "Hecho" hace {{durationString}}.',
  acknowledgedExpiredPersonalAlarmForParticipant:
    'Esta alarma expiró hace {{durationString}}. {{creatorName}} marcó la alarma como "Hecho".',
  entityNotFound:
    '¡Uy! No hemos podido encontrar la página {{entityType}} seleccionada. Es posible que aún lo estemos recuperando o que haya sido eliminado.',
  goBack: 'Volver atrás',
  uploadingImageToServer: 'Carga de la imagen en el servidor.',
  deletingImageFromServer: 'Borrar imagen del servidor',
  errorUploadingImageToServer:
    'Error al subir la imagen al servidor. Vuelva a intentarlo más tarde.',
  unableToRemoveGroup: 'No se puede eliminar el grupo. {{error}}',
  addingGroup: 'Añadir grupo.',
  editingGroup: 'Grupo de edición.',
  unableToAddGroup: 'No se puede añadir grupo. {{error}}',
  unableToEditGroup: 'No se puede editar el grupo. {{error}}',
  thanksForFeedback: 'Gracias por ayudarnos a mejorar.',
  licensesHeader:
    'Galarm incluye software con licencia de terceros. Consulte nuestra página de licencias de terceros para obtener la información correspondiente sobre atribución y licencias.',
  alarmAlreadyInSnooze: 'Recibirá un recordatorio en {{durationString}}.',
  carrierChargesMayApply: 'Pueden aplicarse gastos de transporte.',
  selectCascadingInterval:
    'El primer participante será alertado {{firstParticipantDurationString}} después de la hora de alarma, los segundos participantes serán alertados {{secondParticipantDurationString}} después de la hora de alarma y así sucesivamente si no marca la alarma como hecha.',
  reorderParticipantsScreenDescription:
    'Se avisará a los participantes en el orden indicado.',
  noMembers: 'No hay miembros',
  selectedGroupDescription: {
    zero: '{{numActiveMembers}} participantes',
    one: '{{numActiveMembers}} participante activo e inactivo {{numInactiveMembers}} ',
    other:
      '{{numActiveMembers}} participantes activos e inactivos {{numInactiveMembers}} '
  },
  groupAlarmWillRingAtSameTime:
    'Se avisará a todos los participantes en {{timeString}}.',
  welcomeToGalarm: 'Bienvenido a Galarm',
  checkingConnection: 'Comprobación de la conexión',
  deletePastAlarmsTitle: 'Borrar alarmas inactivas anteriores a',
  deletePastAlarmsOption: {
    zero: '{{option}} (0 alarmas)',
    one: '{{option}} (1 alarma)',
    other: '{{option}} ({{count}} alarms)'
  },
  chooseSnoozeInterval: 'Recuérdamelo de nuevo en',
  personalAlarmDescription:
    'Utiliza una alarma personal para despertarte, tomar la medicación o realizar cualquier otra tarea por ti mismo. Opcionalmente, puede añadir participantes que reciban un aviso si se le pasa una alarma para que puedan recordárselo. Los participantes también reciben una notificación cuando marcas la alarma como Hecho.',
  groupAlarmDescription:
    'Utilice una alarma de grupo para actividades en grupo como salidas, fiestas o cualquier otro evento. La alarma suena a la misma hora para todos los participantes y éstos pueden confirmar su participación.',
  recipientAlarmDescription:
    'Crea alarmas para otra persona ("amigo") para recordarle las cosas que tiene que hacer. Recibirás una alerta para recordárselo a tu amigo en caso de que se salte la alarma. También recibirás una notificación cuando el amigo marque la alarma como Hecho.\n  \nTen en cuenta que las alarmas se crean en tu zona horaria.',
  whatsThis: '¿Qué es esto?',
  alarmCurrentlyDisabled: 'Esta alarma está desactivada.',
  alarmCurrentlyRejected: 'Esta alarma está actualmente rechazada',
  currentlyTypingUsers: {
    one: '{{currentlyTypingUsers}} está tecleando...',
    other: '{{currentlyTypingUsers}} están tecleando...'
  },
  noSearchResultsFound: 'No se ha encontrado {{type}} ',
  noGroupsCreated: 'No has creado ningún grupo.',
  noGroupsWeb: {
    one: 'No has creado ningún grupo.',
    other:
      'Regístrese en la aplicación móvil para empezar a crear grupos y añadirlos a las alarmas.'
  },
  internalErrorDuringRegistration:
    'Se ha producido un error interno durante el registro del usuario. Vuelva a intentarlo pasados unos minutos',
  selectRecipientAlarmInterval: {
    zero: 'Se le avisará al mismo tiempo que al destinatario',
    one: 'Recibirá una alerta {{durationString}} después de la hora de alarma si el destinatario no marca la alarma "Hecho".',
    other:
      'Recibirá una alerta {{durationString}} después de la hora de alarma si el destinatario no marca la alarma "Hecho".'
  },
  selectRecipient: 'Seleccionar destinatario',
  acknowledgedExpiredRecipientAlarm:
    'Esta alarma expiró hace {{durationString}}. Has marcado esta alarma como "Hecho".',
  skippedExpiredRecipientAlarm:
    'Esta alarma expiró hace {{durationString}}. Ha saltado esta alarma.',
  expiredRecipientAlarm:
    'Esta alarma expiró hace {{durationString}}. Toca "Hecho" para completar la alarma y notificarlo a {{creatorName}}.',
  acknowledgedRecipientAlarm:
    'Usted marcó esta alarma como "Hecho" hace {{durationString}}.',
  skippedRecipientAlarm: 'Te saltaste esta alarma hace {{durationString}}.',
  acknowledgedExpiredRecipientAlarmForCreator:
    'Esta alarma expiró hace {{durationString}}. {{recipientName}} marcó la alarma como "Hecho".',
  skippedExpiredRecipientAlarmForCreator:
    'Esta alarma expiró hace {{durationString}}. {{recipientName}} saltó la alarma.',
  expiredRecipientAlarmForCreator:
    'Esta alarma caducó hace {{durationString}}. Por favor, recuérdelo a {{recipientName}}.',
  acknowledgedRecipientAlarmForCreator:
    '{{recipientName}} marcó la alarma "Hecho" hace {{durationString}}.',
  selectRecipientForRecipientAlarm: 'Seleccione un destinatario para la alarma',
  selectAtLeastOneParticipantForGroupAlarm:
    'Añadir al menos un participante a la alarma',
  addAtLeastOneMemberToGroup: 'Añadir al menos un miembro al grupo',
  atTheSameTime: 'Al mismo tiempo',
  myself: 'Yo mismo',
  group: 'Grupo',
  someoneElse: 'Otra persona',
  participantsAreNotified: 'Se notifica a los participantes.',
  creatorIsNotified: '{{creatorName}} es notificado.',
  membersAddedToGroup: {
    one: '{{memberNames}} se añade al grupo {{groupName}}.',
    other: '{{memberNames}} se añaden al grupo {{groupName}}.'
  },
  memberRemovedFromGroup:
    '{{memberName}} se ha eliminado del grupo {{groupName}}, lo que puede haber afectado a algunas de sus alarmas.',
  youRemovedFromGroup:
    'Has sido eliminado del grupo {{groupName}}, lo que puede haber afectado a algunas de tus alarmas.',
  memberLeftGroup:
    '{{memberName}} grupo de la izquierda {{groupName}} que puede haber afectado a algunas de sus alarmas.',
  youLeftGroup:
    'Has dejado el grupo {{groupName}}, lo que puede haber afectado a algunas de tus alarmas.',
  groupLeft: 'Has abandonado el grupo {{groupName}}.',
  memberMovedToADifferentTimezone:
    '{{memberName}} se ha trasladado a otra zona horaria, lo que puede haber afectado a algunas de sus alarmas.',
  memberDstChanged: {
    zero: '{{memberName}} ha dejado de respetar el horario de verano. Se han actualizado las alarmas correspondientes.',
    one: '{{memberName}} ha empezado a respetar el horario de verano. Se han actualizado las alarmas correspondientes.'
  },
  impactedAlarms: 'Alarmas afectadas:',
  alwaysRingParticipantAlarmsOn:
    'Las alarmas de los demás sonarán a menos que las rechaces.',
  alwaysRingParticipantAlarmsOff:
    'Las alarmas de los demás no llamarán a menos que las aceptes.',
  agreeToTermsOfServiceAndPrivacyPolicy:
    'Al continuar, usted acepta las Condiciones de servicio y la Política de privacidad de Galarm.',
  deleteAccountWarningHeader:
    'Advertencia: Esta acción es irreversible. La eliminación de su cuenta:',
  deleteAccountDeleteAlarms: '- Borrar todas las alarmas creadas',
  deleteAccountDeleteRecipientAlarms:
    '- Borrar todas las alarmas de amigos en las que usted es el destinatario',
  deleteAccountRemoveFromGroups: '- Eliminarte de todos los grupos',
  deleteAccountWarningFooter: {
    zero: 'Si vas a cambiar de teléfono, puedes instalar la aplicación en el nuevo y registrarte con el mismo número. Puedes eliminar la aplicación en este teléfono.\n\nUna vez que elimines tu cuenta, no podrás restaurarla.',
    one: 'Si vas a cambiar de teléfono, puedes instalar la aplicación en el nuevo y registrarte con el mismo número. Puedes eliminar la aplicación en este teléfono.\n\nUna vez que elimine su cuenta, no podrá restaurarla. Puedes gestionar tu suscripción activa a Galarm en Play Store.',
    other:
      'Si vas a cambiar de teléfono, puedes instalar la aplicación en el nuevo y borrar la de este.\n\nUna vez que elimine su cuenta, no podrá restaurarla. Puedes gestionar tu suscripción activa a Galarm en la App Store.'
  },
  typeConfirmationStringToDeleteAccount:
    'Escribe {{confirmationString}} para eliminar tu cuenta',
  unableToDeleteAccount: 'No se puede eliminar la cuenta. {{error}}',
  impactedGroups: 'Grupos afectados:',
  memberDeletedAccount:
    '{{memberName}} ha eliminado su cuenta, lo que puede haber afectado a algunas de tus alarmas y grupos.',
  confirmCountryCodeAndEnterNumberForExistingUser:
    'Confirme el prefijo de su país e introduzca el número de teléfono que utilizó al registrarse.',
  requiredPermissionsDisabledWarningIos:
    'Las notificaciones están desactivadas. Las alarmas no llaman. Toca para solucionarlo.',
  requiredPermissionsDisabledWarningAndroid:
    'Galarm no tiene los permisos necesarios para llamar a las alarmas. Toca para solucionarlo.',
  notificationChannelPopupDisabledWarningAndroid:
    'Las notificaciones de alarma no aparecen en la pantalla. Toca para solucionarlo.',
  alarmLockScreenNotificationDisabledWarningAndroid:
    'Las notificaciones de alarma no aparecerán en la pantalla de bloqueo. Pulse para solucionarlo.',
  why: 'Por qué',
  how: 'Cómo',
  registrationRequired: 'Inscripción obligatoria',
  phoneNumberRequired: 'Número de teléfono obligatorio',
  fewSeconds: 'unos segundos',
  lessThanMinute: 'menos de un minuto',
  updateUnsupportedVersion:
    'Para seguir utilizando Galarm es necesario disponer de una versión actualizada de la aplicación.',
  update: 'Actualización',
  back: 'Volver',
  unableToMarkAlarmDone:
    'No se puede marcar la alarma como hecha en este momento. Por favor, inténtelo de nuevo en unos momentos.',
  unableToMarkAlarmUndone:
    'No se ha podido marcar la alarma como deshecha en esta hora. Por favor, inténtelo de nuevo en unos momentos.',
  unableToMarkAlarmUnskipped:
    'No es posible marcar la alarma como no saltada en este momento. Por favor, inténtelo de nuevo en unos momentos.',
  unableToSkipAlarm:
    'No se puede Saltar la alarma en esta hora. Por favor, inténtelo de nuevo en unos momentos.',
  unableToSetGroupAlarmResponse:
    'No se puede {{response}} la alarma del grupo en esta hora. Por favor, inténtelo de nuevo en unos momentos.',
  unableToDeleteAlarm:
    'No es posible borrar la alarma en este momento. Por favor, inténtelo de nuevo en unos momentos.',
  unableToSetAlarmResponse:
    'No es posible ajustar su respuesta en este momento. Por favor, inténtelo de nuevo en unos momentos.',
  unableToSnoozeAlarm: 'No se puede posponer la alarma.',
  unableToStopSnoozeForAlarm: 'No se puede parar el Posponer de la alarma.',
  unableToUpdateParticipantAlarmRingerSettings:
    'No es posible actualizar los ajustes del timbre en este momento. Por favor, inténtelo de nuevo en unos momentos.',
  updateParticipantPreReminderDuration:
    'No se puede actualizar la duración del recordatorio previo en este momento. Por favor, inténtelo de nuevo en unos momentos.',
  unableToUpdateAlarmRingerSettings:
    'No es posible actualizar los ajustes del timbre en este momento. Por favor, inténtelo de nuevo en unos momentos.',
  unableToUpdateAlarmPreReminderDuration:
    'No es posible actualizar la duración del recordatorio previo en este momento. Por favor, inténtelo de nuevo en unos momentos.',
  retrievingAlarmsFromServer: 'Recuperación de alarmas del servidor...',
  startApp: 'Inicio >',
  phoneNumberRequiredGroupListHeaderText:
    'Grupo es una colección de contactos de Galarm que te permite crear alarmas rápidamente con ellos.\n\nRegístrese con su número de teléfono para encontrar a sus contactos que utilizan Galarm. Puedes añadirlos a grupos.',
  phoneNumberRequiredBuddyAlarmHeaderText:
    'La alarma para amigos se puede utilizar para ajustar alarmas para amigos y familiares con el fin de recordarles las cosas que tienen que hacer.\n\nPara utilizar esta y muchas otras funciones de la aplicación, regístrate con tu número de teléfono.',
  phoneNumberRequiredContactListHeaderText:
    'Los contactos son personas que pueden añadirse como participantes en las alarmas.\n\nVer los contactos que utilizan Galarm registrándose con su número de teléfono. Puede añadirlos como participantes a sus alarmas.',
  phoneNumberRequiredGroupAlarmHeaderText:
    'La alarma de grupo permite ajustar un recordatorio para un grupo de personas.\n\nPara utilizar esta y muchas otras funciones de la aplicación, regístrate con tu número de teléfono.',
  phoneNumberRequiredParticipantsHeaderText:
    'Los participantes actúan como apoyo a esta alarma. Reciben un recordatorio en caso de que se pierda la alarma.\n\nPara utilizar esta y muchas otras funciones de la aplicación, regístrate con tu número de teléfono.',
  youHaveNotRegistered:
    'Actualmente no hay copia de seguridad de tus alarmas.\n\nRegístrese con su número de teléfono. Una vez que te registres, se hará una copia de seguridad de tus alarmas en la nube. Esto te permite restaurar tus alarmas y otros ajustes cuando cambies de teléfono.\n',
  registerNow: 'Regístrese ahora',
  hoursAndMinutes: 'Horas y minutos',
  days: 'Días',
  weeks: 'Semanas',
  months: 'Meses',
  years: 'Años',
  daysOfWeek: 'Semanal (días seleccionados)',
  monthly: 'Mensualmente',
  yearly: 'Anualmente',
  everyDay: 'Diario',
  selectDaysOfWeek: 'Seleccione los días de la semana',
  alarmRingMessageForSpecificDates:
    'Esta alarma sólo llamará en los meses que tengan al menos {{dayOfMonth}} días. ¿Desea continuar?',
  alarmRingMessageForSpecificYear:
    'Esta alarma sólo llamará en los años bisiestos. ¿Desea continuar?',
  alarmRingMessageForSpecificDayOfWeekInMonth:
    'Esta alarma sólo llamará los meses en los que {{weekNumberOfDayInMonth}} {{dayName}} s. ¿Desea continuar? ',
  alarmRepetitionAdjustedBasedOnNewDate:
    'La repetición de la alarma se ha actualizado en función de la nueva fecha.',
  inviteFriendsToInstallGalarm:
    'Los contactos son personas que pueden añadirse como participantes a sus alarmas. Puedes invitar a contactos:\n\n1. Desde su libreta de direcciones\n\n2. Proporcionando manualmente su nombre y número.\n\nPulse Actualizar en la parte superior izquierda para volver a cargar los contactos de Galarm después de que se registren.',
  whyCreateNewGroup:
    'Los grupos son grupos de contactos que pueden añadirse como participantes en tus alarmas.\n  \nNo has creado ningún grupo.',
  noNotifications: 'No tienes nuevas notificaciones.',
  createGroup: 'Crear grupo',
  galarmRequiresAccessToContactsPermission:
    'Galarm solicita permiso para acceder a tus contactos. Esta información se envía a sus servidores para identificar a sus contactos que utilizan Galarm. Estos usuarios pueden ser añadidos como participantes a sus alarmas.',
  galarmRequiresAccessToAudioFilesPermission:
    'Algunas alarmas utilizan la música del teléfono como tono de llamada. Por favor, proporcione permiso para acceder a los archivos de audio en su dispositivo para llamar a esas alarmas con el tono de llamada elegido.',
  galarmRequiresAccessToContactsSelectScreen:
    'Galarm no tiene permiso para acceder a los contactos. Por favor, permita el acceso a los contactos si desea utilizar esta función.',
  allow: 'Permitir',
  deny: 'Denegar',
  galarmRequiresAccessToContacts:
    'Los contactos son personas que pueden añadirse como participantes en sus alarmas. Puede añadir contactos por:\n\n1. Importando automáticamente desde los contactos del teléfono. Pulse "Permitir acceso" y active "Contactos".\n\n2. Añadiendo manualmente su nombre y número. Pulse "Añadir contacto" para empezar.',
  galarmRequiresAccessToAlarmAndRemindersPermission:
    'Galarm necesita el permiso "Alarmas y recordatorios" para poder llamar a las alarmas exactamente a las horas especificadas. Por favor, habilítelo en la siguiente pantalla.',
  accessToContactsPermissionRequiredAndroid:
    'Galarm no tiene permiso para acceder a los contactos de tu teléfono.\n\nCuando permitas el acceso, las personas de tus contactos telefónicos que estén utilizando Galarm aparecerán como contactos de Galarm. Estas personas pueden añadirse como participantes en la alarma. Pulse "Permitir acceso", seleccione "Permiso" y active "Contactos".\n\nPuedes seguir añadiendo contactos manualmente a Galarm.',
  accessToContactsPermissionRequiredIos:
    'Galarm no tiene permiso para acceder a los contactos de tu teléfono.\n\nCuando permitas el acceso, las personas de tus contactos telefónicos que estén utilizando Galarm aparecerán como contactos de Galarm. Estas personas pueden añadirse como participantes en la alarma. Pulse "Permitir acceso" y active "Contactos".\n\nPuede seguir añadiendo contactos manualmente a Galarm.',
  giveAccess: 'Permitir el acceso',
  enterValidMobileNumber:
    'Introduzca un número de teléfono válido para registrarse.',
  proceedAnyway: 'Proceda de todos modos',
  checkInvalidPhoneNumber:
    'Creemos que ha introducido un número no válido como {{mobileNumber}}.',
  checkInvalidCountryCode:
    'Código de país no válido especificado como {{countryCode}}. Si hay más de un código de país, edite el campo para mantener el código de país aplicable.',
  editNumber: 'Editar número',
  editCountryCode: 'Editar código de país',
  sendingCodeOnCall: 'Llamándote con el código.',
  sendCodeOnCall: 'Llámame con el código',
  registrationLandingScreenRegisterText:
    'Pulse Continuar para registrarse con su número de teléfono.\n\nAl registrarse:\n1) Podrás añadir participantes a tus alarmas y chatear con ellos.\n2) Se realizará una copia de seguridad de sus alarmas en la nube, de modo que cuando cambie de teléfono, sus alarmas aparecerán en el nuevo teléfono.',
  continue: 'Continúe en',
  registerLater: 'Saltar Registro',
  cancelRegistration: 'Cancelar inscripción',
  welcome: 'Bienvenido',
  haventReceivedCodeYet: {
    one: '¿Aún no ha recibido el código? Puede volver a solicitar el código en 1 segundo.',
    other:
      '¿Aún no has recibido el código? Puede volver a solicitar el código en {{count}} segundos.'
  },
  storagePermissionDenied:
    'No se puede acceder a los archivos de música porque el permiso de almacenamiento está denegado.',
  havingTroubleRegisteringMessage:
    'Galarm no puede verificar el código tras múltiples intentos.\n\nQuieres Saltar el registro y empezar a utilizar la aplicación?',
  autoDetectVerificationCode:
    'Para simplificar el registro, Galarm puede detectar automáticamente el código de verificación si permites que Galarm vea los mensajes SMS.',
  notNow: 'Ahora no',
  verifyNumberText:
    'Le enviaremos un SMS con el código de verificación:\n\n{{formattedMobileNumber}}',
  addToContacts: 'Añadir a contactos',
  todayString: 'Hoy',
  tomorrowString: 'Mañana',
  backups: 'Apoyos',
  acintyoTrademark:
    '2024 Acintyo, Inc. Todos los derechos reservados.\nGalarm es una marca registrada de Acintyo, Inc.\nProtegida por las patentes estadounidenses 10382613, 10867286.\nOtras patentes pendientes.',
  acintyoTrademarkWeb:
    '2024 Acintyo, Inc. Todos los derechos reservados. Galarm es una marca registrada de Acintyo, Inc.\nProtegida por las patentes estadounidenses 10382613, 10867286. Otras patentes pendientes.',
  acintyoTrademarkEnterprise:
    '2024 Acintyo, Inc.\nTodos los derechos reservados.',
  markAlarmDone: 'Marcar alarma como Hecho',
  skipAlarm: 'Saltar alarma',
  confirmGroupAlarm: 'Confirmar participación',
  declineGroupAlarm: 'Rechazar participantes',
  snoozeAlarm: 'Posponer alarma',
  deleteAlarmQuickAction: 'Borrar alarma',
  disableAlarm: 'Desactivar alarma',
  confirmDisableAlarm: '¿Desactivar alarma?',
  saveToCalendar: 'Exportar al calendario',
  stillRingGroupAlarmAfterConfirmingInAdvance:
    'Está confirmando la alarma de grupo por adelantado.  ¿Sigue queriendo el recordatorio a la hora de la alarma?',
  updateNow: 'Actualizar ahora',
  nParticipants: {
    one: '1 otro participante',
    other: '{{count}} otros participantes'
  },
  details: 'Detalles',
  changeParanthesis: '(cambio)',
  loadMoreHistory: 'Ver más',
  alarmSummaryForHistory:
    'Respuestas anteriores para {{alarmName}}.\nRepite {{alarmRepetitionString}}\n{{previousOccurrencesString}}',
  alarmSummaryForHistoryWeb: 'Respuestas anteriores para {{alarmName}}',
  alarmSummaryForHistoryRepetitionWeb: 'Repite {{alarmRepetitionString}}',
  dontSeeCompleteHistory: '¿No ve la historia completa?',
  dontSeeCompleteHistoryExplanation:
    'El historial de alarmas se registra desde la hora en que se crea la alarma. Sólo mantenemos un historial limitado para cada alarma en función de la repetición de la alarma. Por ejemplo, si la alarma se repite todos los días, mantenemos un historial de 30 días.\n\nSi cambia la hora o la repetición de la alarma, se restablecerá el historial de alarmas.',
  noActionAvailableForAlarmOccurrence:
    'No hay acciones disponibles para este suceso.',
  enterEmailIdForFeedback:
    'Se utiliza para responder y compartir actualizaciones',
  noPreviousOccurrences: 'Aún no hay historial de alarmas',
  nMissedOccurrencesOfAlarmInLastNDays: {
    zero: 'Aún no hay historial de alarmas',
    one: '{{missedAlarmOccurrences}} perdió el último {{count}}',
    other: '{{missedAlarmOccurrences}} perdió el último {{count}}'
  },
  soundAndVibrationSummaryString: {
    zero: '{{volume}}% volumen, {{ringtoneName}}',
    one: '{{volume}}% volumen, {{ringtoneName}}, Anunciar Título'
  },
  soundAndVibrationSummaryStringIos: {
    zero: '{{ringtoneName}}',
    one: 'Crítico, {{volume}}% volumen, {{ringtoneName}}'
  },
  previousOccurrences: 'Historial de alarmas',
  retrieving: 'Recuperando...',
  startTimeOfDay: 'Comienza en',
  endTimeOfDay: 'Termina en',
  ringEveryHours: 'Llama a cada',
  everyNHours: 'Por hora',
  everyMHoursNMinutes: 'Horas y minutos',
  selectHourlyRepetition: 'Seleccionar repetición horaria',
  everyNHoursRepetitionString: {
    one: 'Cada hora de {{startTimeString}} a {{endTimeString}} cada {{daysString}}',
    other:
      'Cada {{count}} horas de {{startTimeString}} a {{endTimeString}} cada {{daysString}}'
  },
  everyNHoursAndMinutesRepetitionString:
    'Cada {{hoursAndMinutes}} de {{startTimeString}} a {{endTimeString}} cada {{daysString}}',
  everyMHoursAndNMinutesRepetitionString: 'Cada {{repeatString}}',
  everyNHoursRepetitionStringForCalendar: {
    one: 'Cada hora de {{startTimeString}} a {{endTimeString}}',
    other: 'Cada {{count}} horas de {{startTimeString}} a {{endTimeString}}'
  },
  everyNHoursAndMinutesRepetitionStringForCalendar:
    'Cada {{hoursAndMinutes}} de {{startTimeString}} a {{endTimeString}}',
  everyNHoursAndMinutesRepetitionStringForAlarmSummary:
    'Cada {{hoursAndMinutes}} hasta {{endTimeString}}',
  everyNHoursRepetitionStringForAlarmSummary: {
    one: 'Cada hora hasta {{endTimeString}}',
    other: 'Cada {{count}} horas hasta {{endTimeString}}'
  },
  daysRepetitionOddString: {
    other: 'Todas las fechas impares',
    one: 'Fechas impares ({{daysString}})'
  },
  daysRepetitionEvenString: {
    other: 'Todas las fechas pares',
    one: 'Fechas pares ({{daysString}})'
  },
  oddNumberedRepetitionPreviewString: {
    one: 'La alarma llamará en las fechas impares que caigan en {{daysString}} a la hora especificada.',
    other:
      'La alarma llamará en todas las fechas impares a la hora especificada.'
  },
  evenNumberedRepetitionPreviewString: {
    one: 'La alarma llamará en las fechas pares que caigan en {{daysString}} a la hora especificada.',
    other: 'La alarma llamará en todas las fechas pares a la hora especificada.'
  },
  hourlyRepetitionPreviewString: {
    one: 'Esta alarma llamará una vez cada {{daysString}} a las:',
    other: 'Esta alarma llamará {{count}} veces cada {{daysString}} a las:'
  },
  daysRepetitionPreviewString: {
    one: 'Esta alarma llamará todos los días.',
    other: 'Esta alarma llamará cada {{count}} días.'
  },
  weeksRepetitionPreviewString: {
    one: 'Esta alarma llamará cada semana.',
    other: 'Esta alarma llamará cada {{count}} semanas.'
  },
  everyNDays: { one: 'Todos los días', other: 'Cada {{count}} días' },
  everyNWeeks: { one: 'Cada semana', other: 'Cada {{count}} semanas' },
  everyNMonths: { one: 'Todos los meses', other: 'Cada {{count}} meses' },
  everyNYears: { one: 'Todos los años', other: 'Cada {{count}} años' },
  cantSetEndTime: 'No se puede ajustar la hora de finalización.',
  endTimeShouldBeGreater:
    'La hora de finalización debe ser mayor que la hora de inicio.',
  cantSetStartTime: 'No se puede ajustar la hora de inicio',
  startTimeShouldBeLess:
    'La hora de inicio debe ser inferior a la hora de finalización.',
  everyDaySmall: 'día',
  everyGivenDay: '{{day}}',
  everyGivenDays: '{{days}}',
  adjustingAlarmsAfterTimezoneChange:
    'Hemos detectado que se ha trasladado a otra zona horaria. Estamos ajustando tus alarmas.',
  ringOnDays: 'Anillos en',
  yourAlarmsMayNotRing: 'Puede que no llamen sus alarmas',
  yourAlarmsMayNotRingTapToFix:
    'Puede que tus alarmas no llamen. Toca para solucionarlo.',
  xiaomiSetupInstructions:
    'Sigue estas instrucciones para que tus alarmas llamen siempre:\n\n - Toca "Activar inicio automático" y permite "Galarm" para que tus alarmas siempre llamen. \n\nPara más información, visite www.galarmapp.com/setup-mi',
  oppoSetupInstructions:
    'Sigue estas instrucciones para que tus alarmas llamen siempre:\n\n - Pulse "Activar inicio automático" y active "Permitir inicio automático".\n\n - Pulse el botón "Aplicaciones recientes" de su teléfono, deslice el icono de la aplicación Galarm hacia abajo y pulse "Bloquear".  \n\nPara más información, visita www.galarmapp.com/setup-oppo',
  oppo8AndLowerSetupInstructions:
    'Sigue estas instrucciones para que tus alarmas llamen siempre:\n\n - Pulse "Activar inicio automático" y active "Permitir inicio automático".\n\n - Pulse el botón "Aplicaciones recientes" de su teléfono, deslice el icono de la aplicación Galarm hacia abajo y pulse "Bloquear".\n\nPara más información, visita www.galarmapp.com/setup-oppo-8-and-lower',
  vivo9And10SetupInstructions:
    'Sigue estas instrucciones para que tus alarmas llamen siempre:\n\n - Toca "Activar inicio automático" y permite "Galarm".\n\n - Toca "Uso de la batería", selecciona "Alto consumo de energía en segundo plano" y permite "Galarm".\n\nPara más información, visite www.galarmapp.com/setup-vivo',
  vivo11SetupInstructions:
    'Sigue estas instrucciones para que tus alarmas llamen siempre:\n\n- Toca "Activar inicio automático" y permite "Galarm".\n\n- Toca "Uso de la batería", selecciona "Alto consumo de energía en segundo plano" y permite "Galarm".\n\nPara más información, visite www.galarmapp.com/setup-vivo-11',
  vivoSetupInstructions:
    'Sigue estas instrucciones para que tus alarmas llamen siempre:\n\n- Abre "Ajustes del teléfono > Aplicaciones > Acceso a aplicaciones especiales > Inicio automático" y activa el inicio automático para Galarm.\n\n- Abre "Ajustes del teléfono > Batería > Gestión del consumo de energía en segundo plano > Galarm" y selecciona "No restringir el consumo de energía en segundo plano".\n\nPara obtener más información, visite www.galarmapp.com/setup-vivo-12-and-above',
  vivo8AndLowerSetupInstructions:
    'Sigue estas instrucciones para que tus alarmas llamen siempre:\n\n - Pulse "Activar inicio automático" y permita "Galarm".\n\n - Toque "Uso de la batería", seleccione "Alto consumo de energía en segundo plano" y permita "Galarm".\n\nPara más información, visite www.galarmapp.com/setup-vivo-8-and-lower',
  huawei7AndLowerAdditionalSetupInstructions:
    'Sigue estas instrucciones para que tus alarmas llamen siempre:\n\n - Toca "Aplicaciones protegidas" y permite "Galarm".\n\nPara más información, visite www.galarmapp.com/setup-huawei-7-and-lower',
  huaweiAdditionalSetupInstructions:
    'Sigue estas instrucciones para que tus alarmas llamen siempre:\n\n - Pulse "Administrar lanzamiento de aplicaciones" y desactive "Galarm" para "Administrar manualmente". En el mensaje que aparece a continuación, asegúrate de que "Inicio automático" y "Ejecutar en segundo plano" están activados.\n\nPara más información, visita www.galarmapp.com/setup-huawei',
  meizuSetupInstructions:
    'Siga las instrucciones del siguiente enlace para que sus alarmas llamen siempre: https://dontkillmyapp.com/meizu',
  asusSetupInstructions:
    'Sigue estas instrucciones para que tus alarmas llamen siempre:\n\n - Pulse "Activar inicio automático" y haga clic en "Gestor de inicio automático". Active "Galarm" en el "Gestor de inicio automático".\n\nPara más información, visite www.galarmapp.com/setup-asus',
  nokia8AndLowerAdditionalSetupInstructions:
    'Sigue estas instrucciones para que tus alarmas llamen siempre:\n\n - Toca "Activar inicio automático" y permite "Galarm".\n\nPara más información, visite www.galarmapp.com/setup-nokia-8-and-lower',
  nokiaAdditionalSetupInstructions:
    'Sigue estas instrucciones para que tus alarmas llamen siempre:\n\n - Toca "Aplicaciones restringidas" y desactiva "Galarm".\n\nPara obtener más información, visite www.galarmapp.com/setup-nokia',
  lenovoSetupInstructions:
    'Sigue estas instrucciones para que tus alarmas llamen siempre:\n\n - Pulsa el botón "Aplicaciones recientes" de tu teléfono, localiza la aplicación Galarm y pulsa el icono "Bloquear" en la esquina superior derecha.\n  \nPara más información, visite https://dontkillmyapp.com/lenovo',
  samsungAdditionalSetupInstructions:
    'Sigue estas instrucciones para que tus alarmas llamen siempre:\n\n - Toca "Aplicaciones no supervisadas" y permite "Galarm".\n\nPara obtener más información, visite www.galarmapp.com/setup-samsung',
  tecnoSetupInstructions:
    'Siga las instrucciones del siguiente enlace para que sus alarmas llamen siempre: https://www.galarmapp.com/setup-tecno',
  infinixSetupInstructions:
    'Siga las instrucciones del siguiente enlace para que sus alarmas llamen siempre: https://www.galarmapp.com/setup-infinix',
  samsungAdditionalSetupInstructionsAndroid11AndAbove:
    'Por defecto, Samsung muestra notificaciones "Breves" que desaparecen rápidamente. Siga estas instrucciones para ver notificaciones de alarma "Detalladas".\n\n - Abre los ajustes del teléfono y elige "Notificaciones".\n - Elige el estilo de notificaciones "Detalladas\n  \nPara más información, visite www.galarmapp.com/setup-samsung-android11-and-above',
  iosSetupInstructions:
    'Por defecto, el estilo de banner de iOS para las notificaciones está ajustado a "Temporal", lo que hace que las notificaciones desaparezcan rápidamente. Siga estas instrucciones para ajustar el estilo de banner a "Persistente".\n\n- Abra los ajustes del teléfono y desplácese hasta Galarm\n- Selecciona Notificaciones y ajusta el Estilo de pancarta a Persistente.\n\nPara obtener más información, visita www.galarmapp.com/setup-ios',
  iosSetupInstructions15AndBelow:
    'Por defecto, el estilo de banner de iOS para las notificaciones está ajustado a "Temporal", lo que hace que las notificaciones desaparezcan rápidamente. Siga estas instrucciones para ajustar el estilo de banner a "Persistente".\n\n- Abra los ajustes del teléfono y desplácese hasta Galarm\n- Selecciona Notificaciones y ajusta el Estilo de pancarta a Persistente.\n\nPara obtener más información, visita www.galarmapp.com/setup-ios-15-below',
  setNotificationsStyle: 'Ajuste del estilo de las notificaciones',
  setNotificationsStyleToDetailed:
    'Ajuste el estilo de las notificaciones a "Detallado".',
  setBannerStyle: 'Ajuste del estilo de la pancarta',
  setBannerStyleToPersistent: 'Ajuste el estilo del banner a "Persistente".',
  selectAtleastOneDay:
    'Seleccione los días en los que debe llamar esta alarma.',
  selectAtLeastOneHourOrMinute:
    'Las horas o los minutos deben ser un valor distinto de cero',
  hourlyAlarmDefaultStartTimeSetAlert:
    'La hora de inicio de la alarma se ha ajustado a las 8 AM.',
  batteryUsage: 'Uso de la batería',
  dontShowAgain: 'No vuelvas a aparecer',
  alarmWillRingEveryHourTillSeen:
    'Posponerás la alarma cada hora hasta que hagas algo.',
  unknown: 'Desconocido',
  alarmMarkedDone: '{{name}} ha marcado la alarma "Hecho".',
  alarmMarkedSkipped: '{{name}} ha saltado la alarma.',
  alarmIsMarkedDone: '{{alarmName}} está marcado como Hecho.',
  alarmIsSkipped: '{{alarmName}} se salta.',
  alarmIsConfirmed: '{{alarmName}} está confirmado.',
  alarmIsDeclined: '{{alarmName}} se rechaza.',
  alarmIsSnoozed: '{{alarmName}} es Posponer.',
  alarmIsAccepted: '{{alarmName}} se acepta.',
  alarmIsRejected: '{{alarmName}} es rechazada.',
  newContactInfo:
    'Indique el nombre y el número de teléfono de la persona de contacto.\n\nSólo los usuarios registrados pueden ser añadidos como contactos. Si el usuario no está registrado en Galarm, puedes invitarlo a través de "Ajustes > Compartir esta aplicación". Puedes añadirlo como contacto después de que se registre utilizando su número de teléfono. ',
  cantEditContactMobileNumber:
    'No puedes editar el número de teléfono. Borra el contacto y vuelve a añadirlo si quieres cambiar el número.',
  phoneContactsNotSyncedPermissionDenied:
    'Galarm no tiene permiso para acceder a los contactos del teléfono. Los contactos telefónicos no se sincronizarán.',
  inviteContactMessage:
    'Hola {{contactName}}, quiero incluirte en mis alarmas y recordatorios usando Galarm. Descarga la aplicación desde {{downloadLink}}. Regístrate con este número y empieza a compartir alarmas.',
  noRepeat: 'No repite',
  deepLinkNoLongerValidTitle: '¡Uy!',
  deepLinkNoLongerValidMessage:
    'No se ha creado la alarma. Puede tratarse de un enlace antiguo o no válido.',
  inviteContact: 'Invitar a un contacto',
  fromPhoneBook: 'Desde la libreta de direcciones',
  addFromPhoneBook: 'Invitar desde la libreta de direcciones',
  manually: 'Manualmente',
  addManualContact: 'Invitar manualmente',
  noOtherContactsFound: 'Todos los contactos de tu agenda utilizan Galarm.',
  cantAddPhoneContactPermissionDenied:
    'Galarm no tiene permiso para acceder a los contactos del teléfono. Permitir el acceso a los contactos.',
  alarmWillRingWithRandomRingtone:
    'Se elegirá un tono de llamada aleatorio para la alarma.',
  alarmWillRingWithRandomRingtoneGlobal:
    'Se elegirá un tono de llamada aleatorio para las alarmas.',
  createdAlarmFromDeepLinkTitle: 'Ya está todo listo.',
  createdAlarmFromDeepLinkMessage:
    'Se le recordará "{{alarmName}}" en {{alarmTime}} en {{alarmDate}}',
  createdRepeatingAlarmFromDeepLinkMessage: {
    zero: 'Se le recordatorio de "{{alarmName}}" {{repetitionString}}',
    one: 'Se le recordará "{{alarmName}}" {{repetitionString}} en {{alarmTime}} a partir de {{alarmDate}}'
  },
  deleteCategoryAlertTitle: '¿Borrar categoría?',
  deleteCategoryAlertMessage: {
    zero: '',
    one: '1 alarma pertenece a esta categoría. Al eliminar esta categoría, se restablecerá la categoría de esa alarma.',
    other:
      '{{count}} alarmas pertenecen a esta categoría. Al eliminar esta categoría se restablecerá la categoría en esas alarmas.'
  },
  updateReadyToInstallAlertTitle: '¿Instalar actualización?',
  updateReadyToInstallAlertMessage:
    'Las actualizaciones están listas para instalarse. ¿Desea instalar la actualización ahora? ',
  hideCategoryAlertTitle: '¿Ocultar categoría?',
  hideCategoryAlertMessage: {
    zero: 'No podrá utilizar esta categoría en el futuro.',
    one: '1 alarma pertenece a esta categoría. Si oculta esta categoría, se restablecerá la categoría en esa alarma.\n  \nNo podrá utilizar esta categoría en el futuro.',
    other:
      '{{count}} alarmas pertenecen a esta categoría. Si oculta esta categoría, se restablecerá la categoría en esas alarmas.\n  \nNo podrá utilizar esta categoría en el futuro.'
  },
  alarmCategoryDeleteAllAlarmsPrompt: {
    one: '1 alarma pertenece a esta categoría. ¿Desea eliminarla?\n\nTenga en cuenta que las alarmas de los participantes no se borran.',
    other:
      '{{count}} alarmas pertenecen a esta categoría. ¿Desea eliminarlas?\n\nTenga en cuenta que las alarmas de los participantes no se borran.'
  },
  enterNameForGroup: 'Introduzca un nombre para el grupo',
  enterMobileNumber: 'Introduzca su número de teléfono',
  enterNotesForAlarm: 'Introducir notas para la alarma',
  enterUserName: 'Introduzca su nombre',
  enterEmailId: 'Opcional',
  enterFeedback: '¿Cómo podemos mejorar?',
  describeProblem: 'Describa su problema',
  invited: 'Invitado',
  resend: 'Vuelva a enviar',
  addContact: 'Añadir contacto',
  inviteAgain: 'Invitar de nuevo',
  contactNotRegistered:
    '{{contactName}} aún no ha instalado Galarm. Podrás añadirlos a las alarmas una vez que se instalen y se registren utilizando su número de teléfono.',
  registration: 'Inscripción',
  verify: 'Verificación',
  profile: 'Perfil',
  profileImage: 'Imagen de perfil',
  addProfileImage: 'Añadir imagen de perfil',
  groupDetails: 'Datos del grupo',
  newGroup: 'Nuevo grupo',
  uploadImage: 'Cambiar imagen',
  removeImage: 'Eliminar imagen',
  selectParticipants: 'Seleccionar participantes',
  selectMembers: 'Seleccionar miembros',
  selectTimezone: 'Seleccione la zona horaria',
  searchForATimezone: 'Buscar una zona horaria',
  timezone: 'Huso horario',
  deviceTimezone: 'Zona horaria del dispositivo',
  specifyTimezoneForAlarm: 'Especifique la zona horaria de las alarmas',
  editName: 'Editar nombre',
  groupImage: 'Imagen de grupo',
  addGroupImage: 'Añadir imagen de grupo',
  selectAlarmType: 'Crear una alarma para',
  contactDetails: 'Datos de contacto',
  commonAlarms: 'Alarmas',
  commonGroups: 'Grupos',
  groupAlarmsTitle: 'Alarmas',
  selectRingtone: 'Seleccionar tono de llamada',
  selectMusic: 'Seleccionar música',
  select: 'Seleccione',
  personalAlarm: 'Alarma personal',
  groupAlarm: 'Alarma de grupo',
  newPersonalAlarm: 'Nueva alarma personal',
  newGroupAlarm: 'Nueva alarma de grupo',
  editPersonalAlarm: 'Editar alarma personal',
  editGroupAlarm: 'Editar alarma de grupo',
  rearrangeParticipants: 'Reorganizar a los participantes',
  alarmConversation: 'Chat de alarma',
  timezoneMismatchDetected:
    'Algo nos dice que se encuentra en una zona horaria diferente a la última vez que utilizó Galarm.\n\nPor favor, reinicie Galarm para que podamos adaptarnos a la nueva zona horaria.',
  deviceRestartRequired: 'Es necesario reiniciar la aplicación',
  disabledAlarm: 'Alarma desactivada {{name}}',
  newContact: 'Nuevo contacto',
  contactName: 'Nombre de contacto',
  contactMobileNumber: 'Teléfono de contacto',
  enterNameForContact: 'Introduzca el nombre del contacto',
  pickCountryForContact: 'País del contacto',
  enterMobileNumberForContact: 'Introduzca el número de teléfono del contacto',
  specifyNameForContact: 'Nombre de contacto obligatorio',
  specifyValidPhoneNumberForContact: 'Se requiere un número de teléfono válido',
  addingContact: 'Añadir contacto',
  deletingContact: 'Quitar el contacto',
  editingContact: 'Editar contacto',
  pending: 'Pendiente',
  deleteContact: 'Eliminar contacto',
  galarmContact: 'Añadido manualmente',
  phoneContact: 'Añadido desde contactos telefónicos',
  confirmDeleteContactTitle: 'Eliminar {{name}}?',
  confirmDeleteContact: 'No podrás añadirlos a tus alarmas.',
  contactAdded:
    '¡Genial! Ahora puedes añadir {{name}} como participante en tus alarmas.',
  contactInvited:
    'Se ha enviado un mensaje de texto a {{name}} invitándoles a utilizar Galarm.',
  changeSnoozeDuration: 'Cambio Duración',
  durationOfEvent: 'Duración del acontecimiento',
  upgradeToPremium: 'Actualización a Premium',
  premium: 'Premium',
  alarmLimitReached: 'Límite de alarma alcanzado',
  unableToConnectToGooglePlay:
    'No se puede conectar a Google Play. Asegúrate de haber iniciado sesión en tu cuenta de Google.',
  cantLoadVideo: 'No se puede cargar el vídeo',
  cantUpgradeToPremium: 'No puedo actualizar a Premium',
  unableToConnectToIapAndroid:
    'No se puede conectar a Play Store. Abre la aplicación Play Store y asegúrate de haber iniciado sesión.',
  unableToConnectToIapIos:
    'No se puede conectar a la App Store. Abre la aplicación App Store y asegúrate de haber iniciado sesión.',
  premiumFeaturesIos:
    'Como usuario premium, obtienes un límite ampliado de {{maxLimit}} alarmas simultáneas.\n\nTu compra también nos ayuda a desarrollar más funciones.',
  premiumFeaturesAndroid:
    'Crea alarmas ilimitadas actualizando a premium.\n\nTu compra también nos ayuda a desarrollar más funciones.',
  upgrade: 'Actualización',
  rvInternalError:
    'No hemos podido cargar el vídeo debido a un error interno. Sin embargo, hemos aumentado tu límite de alarma en 1.',
  rvNetworkError:
    'Asegúrate de que tienes una buena conexión a Internet e inténtalo de nuevo.',
  rvNotFoundError:
    'No hemos podido encontrar un vídeo adecuado en esta hora. Sin embargo, hemos aumentado tu límite de alarma en 1.',
  dontRemindMe: 'No me lo recuerdes',
  sameTimeAsBuddy: 'A la misma hora que amigo.',
  noAlertForBuddyAlarm: 'Ha elegido que no se le avise de esta alarma.',
  noAlertRecipientIntervalDescription: 'No recibirá ninguna alerta.',
  chooseHours: 'Elegir horas',
  chooseMins: 'Elija los minutos',
  changeHours: 'Cambiar horas',
  moreOptions: 'Más opciones...',
  set: 'Ajuste',
  chooseNumberOfDays: 'Introduzca el número de días',
  chooseNumberOfWeeks: 'Introduzca el número de semanas',
  numberOfDaysShouldBeAtLeastOne: 'El número de días debe ser al menos 1',
  numberOfWeeksShouldBeAtLeastOne: 'El número de semanas debe ser al menos 1',
  numberOfMonthsShouldBeAtLeastOne: 'El número de meses debe ser al menos 1',
  numberOfYearsShouldBeAtLeastOne: 'El número de años debe ser al menos 1',
  frequency: 'Frecuencia',
  configuration: 'Configuración',
  endDate: 'Fecha de fin de alarma',
  noEndDate: 'Sin fecha de finalización',
  alarmEndDateSetOnAlarm:
    'La fecha de finalización de la alarma se ajusta en {{endDateString}}.',
  noAlarmDateSetForAlarm:
    'No se ha ajustado ninguna fecha de finalización para esta alarma.',
  noOccurrenceForThisAlarmAsPerEndDate:
    'La fecha final no permite ninguna ocurrencia para la alarma.',
  alarmDateShouldBeBiggerThanAlarmStartDate:
    'La fecha de fin de alarma debe ser posterior a la fecha de inicio de alarma.',
  aWeek: 'Una semana',
  aMonth: 'Un mes',
  allInactiveAlarms: 'Todas las alarmas inactivas',
  chooseAlarmEndDate: 'Elija la fecha de finalización de la alarma',
  next: 'Siguiente',
  next7Days: 'Dentro de 7 días',
  next30Days: 'Dentro de 30 días',
  next12Months: '12 meses a partir de hoy',
  next10Years: 'Dentro de 10 años',
  chooseDate: 'Elija una fecha...',
  learnMore: 'Más información',
  repeatEvery: 'Repite cada',
  repeatEveryDays: 'día(s)',
  repeatEveryWeeks: 'semana(s)',
  repeatEveryMonths: 'mes(es)',
  repeatEveryYears: 'año(s)',
  autoSnooze: 'Posponer alarmas automáticamente',
  autoSnoozePerAlarm: 'Posponer alarma automáticamente',
  unableToUploadGroupAvatar:
    'No se puede subir la imagen del grupo ahora. Vuelva a intentarlo más tarde',
  addNewMembersToExistingAlarms:
    'Tiene las siguientes alarmas con este grupo:\n\n{{alarmNames}}\n    \n¿Desea añadir los nuevos miembros a estas alarmas?',
  removeGroupMemberAlert:
    'La eliminación del miembro afectará a las siguientes alarmas:\n\n{{alarmNames}}\n\n¿Aún desea eliminar al miembro?',
  leaveGroupAlert:
    'Salir del grupo afectará a las siguientes alarmas:\n\n{{alarmNames}}\n\n¿Aún quieres abandonar el grupo?',
  updateAppAfterFeedback:
    'Hemos detectado que utilizas una versión antigua de la aplicación. Actualízala para disfrutar de una experiencia mejor.',
  changesAreLost:
    'Tienes cambios sin guardar.\n\n¿Quieres guardarlos antes de irte?',
  recipientAlarmIntervalGreaterThanRepetitionInterval:
    'El intervalo "Recuérdamelo" debe ser menor que el intervalo de repetición.',
  cascadingAlarmIntervalGreaterThanRepetitionInterval:
    '"El intervalo de tiempo entre alertas de participantes debe ser inferior al intervalo de repetición.',
  subscriptionCanceled:
    'Su suscripción premium ha sido cancelada. Las funciones premium no estarán disponibles hasta que renueves la suscripción.',
  unableToRenewSubscription:
    'Se ha producido un problema al renovar su suscripción premium. Asegúrate de que tus datos de pago están actualizados.',
  notice: 'Aviso',
  ringerSettingsWillApplyToNewAlarms:
    'Los cambios que realices en los ajustes de sonido y vibración sólo se aplicarán a las nuevas alarmas.\n\nPara las alarmas existentes, puede editar la alarma para cambiar sus ajustes de sonido y vibración.',
  renew: 'Renovar',
  galarmPremium: 'Galarm Premium',
  payMonthly: 'Pago mensual en {{price}} al mes',
  payYearly: 'Pago anual en {{price}} al año',
  free: 'Gratis',
  unlimited: 'Sin límites',
  personalAlarms: 'Alarmas personales',
  groupAlarms: 'Alarmas de grupo',
  buddyAlarms: 'Alarmas para amigos',
  alarmRepetitions: 'Repeticiones',
  ringtones: 'Tonos de llamada',
  alarmHistory: 'Historial de alarmas',
  base: 'Básico',
  advanced: 'Avanzado',
  complete: 'Completo',
  alarmHistoryFree: 'Limitado',
  phoneMusicAsRingtone: 'Música del teléfono como tono de llamada',
  startFreeTrial: 'Prueba gratuita de 14 días',
  chargedAtEndOfFreeTrial:
    'Se le cobrará una vez finalizada la prueba gratuita de 14 días, a menos que cancele su suscripción.',
  monthlySubscription: 'Mensualmente',
  yearlySubscription: 'Anualmente',
  premiumRingtoneRequiresUpgrade:
    'Este tono de llamada requiere suscripción Premium.',
  defaultPremiumFeaturesDescription:
    'Actualiza a Premium para disfrutar de estas fantásticas funciones.',
  groupAlarmsPremiumFeaturesDescription:
    'Necesitas una suscripción premium para crear más de 1 alarma de grupo.',
  buddyAlarmsPremiumFeaturesDescription:
    'Necesitas una suscripción premium para crear más de 1 alarma para otra persona ("amigo").',
  shareableAlarmLinksPremiumFeaturesDescription:
    'Necesitas una suscripción premium para crear más enlaces de alarma compartibles.',
  viewPremiumFeaturesDescription:
    'Gracias por ser usuario premium. Puedes disfrutar de estas fantásticas funciones como suscriptor premium.',
  accessToPremiumFeatures:
    'Tendrás acceso a estas funciones como parte de la suscripción:',
  alarmCategoryPremiumFeaturesDescription:
    'Necesita una suscripción premium para crear nuevas categorías.',
  galarmWebPremiumFeaturesDescription:
    'Necesitas una suscripción premium para utilizar Galarm en un navegador web.',
  instantAlarmPremiumFeaturesDescription:
    'Necesitas una suscripción premium para crear más alarmas instantáneas.',
  personalAlarmsFeatureDescription:
    'Utiliza una alarma personal para despertarte, tomar la medicación o realizar cualquier otra tarea por ti mismo. Opcionalmente, puede añadir participantes que reciban un aviso si se le pasa una alarma para que puedan recordárselo. Los participantes también reciben una notificación cuando marcas la alarma como Hecho.',
  groupAlarmsFeatureDescription:
    'Utilice una alarma de grupo para actividades en grupo como salidas, fiestas o cualquier otro evento. La alarma suena a la misma hora para todos los participantes y éstos pueden confirmar su participación.',
  buddyAlarmsFeatureDescription:
    'Crea alarmas para otra persona ("amigo") para recordarle las cosas que tiene que hacer. Recibirás una alerta para recordárselo a tu amigo en caso de que se salte la alarma. También recibirás una notificación cuando el amigo marque la alarma como Hecho.',
  alarmRepetitionsFeatureDescription:
    'Cree alarmas con repeticiones avanzadas como cada 30 minutos, cada 2 horas 45 minutos, cada dos días, quincenalmente y muchas más.',
  ringtonesFeatureDescription:
    'Elija entre una amplia variedad de tonos de llamada premium para sus alarmas. Estamos continuamente añadiendo más tonos de llamada a la oferta premium.',
  phoneMusicAsRingtoneFeatureDescription:
    'Elige cualquier canción/música de tu teléfono como tono de llamada de alarma.',
  shareableAlarmLinksFeatureDescription:
    'Puedes compartir alarmas con otras personas mediante un enlace.\n\nPor ejemplo, un profesor puede compartir el enlace de su alarma de clase con los alumnos mediante correo electrónico, WhatsApp o cualquier aplicación de mensajería. Cuando los alumnos pulsen el enlace, se creará una copia de la alarma de clase en sus teléfonos. Todos los alumnos recibirán una alerta a la hora especificada para incorporarse a la clase.',
  instantAlarmFeatureDescription:
    'Alerta instantáneamente a un grupo de personas con sólo pulsar un botón.\n\nUtiliza esta alarma para alertarles de un simulacro de emergencia, el inicio de un partido, una tarea/reunión urgente y otros eventos de este tipo que requieran atención inmediata.',
  alarmHistoryFeatureDescription:
    'Ver todas las respuestas anteriores de cada alarma que se repite. Esto te permite averiguar fácilmente cuándo te perdiste la clase de gimnasia, tu madre se saltó la medicación, etc.',
  announceAlarmTitleFeatureDescription:
    'Anuncia el título de la alarma cuando ésta llame.',
  exportToCalendarPremiumFeaturesDescription:
    'Necesitas una suscripción premium para exportar más alarmas al calendario.',
  exportToCalendarFeatureDescription:
    'Puede exportar alarmas a su calendario. Esto le permite ver fácilmente sus alarmas en el calendario de su dispositivo y reflejar mejor su disponibilidad.',
  galarmWebFeatureDescription:
    'Utilice Galarm en un navegador web para crear, editar y eliminar alarmas.',
  preReminderFeatureDescription:
    'Ajuste recordatorios previos para recibir alertas de las próximas alarmas. Puede configurar la duración relativa de la alerta antes de la hora de alarma real, como 30 minutos antes, 1 día antes, etc.',
  calendarViewFeatureDescription:
    'Ver alarmas en un calendario para ver fácilmente las alarmas de cualquier día.',
  updatePhoneNumberFeatureDescription:
    'Puede cambiar el número de teléfono utilizado para esta cuenta. Todas tus alarmas, contactos, grupos y otros ajustes se migrarán al nuevo número de teléfono.',
  alarmPhotoFeatureDescription:
    'Añada una foto a una alarma para obtener indicaciones visuales rápidas, como la foto de: un medicamento, instrucciones para una tarea, un objeto, etc. La foto se mostrará cuando llame la alarma.',
  taskListFeatureDescription:
    'Cree listas de tareas para mantener juntas las tareas relacionadas.',
  calendarViewPremiumFeature: 'Ver calendario',
  updatePhoneNumberPremiumFeature: 'Cambiar el número de teléfono',
  alarmNotFound: 'Alarma no encontrada',
  alarmNotFoundMessage:
    'Es posible que esta alarma se haya borrado recientemente. Pulse "Eliminar" para desactivar otras notificaciones de esta alarma.',
  alarmExistsWithDeepLinkTitle: 'Duplicar alarma',
  alarmExistsWithDeepLinkMessage:
    'Una alarma creada con este enlace ya existe en tu teléfono.',
  alarmDoesntHaveNextDate: 'La alarma no tiene una fecha siguiente válida.',
  sharePersonalAlarmDeepLinkDescription:
    'Pulse sobre este enlace para crear una alarma para este evento en {{dateString}}.',
  joinGroupAlarmDeepLinkDescription:
    'Pulse en este enlace para unirse a este evento de grupo en {{dateString}}',
  subscribedAlarmEdited:
    '{{alarmCreatorName}} ha actualizado {{alarmName}}. ¿Quiere actualizar su alarma?',
  subscribedAlarmDeleted:
    '{{alarmCreatorName}} ha borrado {{alarmName}}. ¿Quiere borrar su alarma?',
  responseReceivedForTicket:
    'Hemos respondido a su ticket #{{ticketId}} en: {{email}}. Si no está en su bandeja de entrada, por favor, compruebe sus carpetas de spam para un correo electrónico de support@galarm.zendesk.com.',
  newTicketReceived:
    'Gracias por escribirnos. Hemos creado el ticket #{{ticketId}} para realizar un seguimiento de su solicitud.\n\nLe responderemos a la dirección de correo electrónico que nos ha facilitado: {{email}}. Si no es correcta, envíenos de nuevo sus comentarios con la dirección de correo electrónico correcta.\n\nAñade también support@galarm.zendesk.com a tu libreta de direcciones de correo electrónico para garantizar la correcta entrega de nuestras respuestas. También le enviaremos una notificación aquí cuando hayamos respondido a su solicitud.',
  sentAccountBackup:
    'Hemos enviado tus datos de alarma a {{email}}. Si no está en tu bandeja de entrada, por favor, comprueba si hay un correo electrónico de noreply@acintyo.com en tus carpetas de spam.',
  alarmDoesntExist: 'La alarma no existe.',
  shareLink: 'Compartir enlace',
  joinedGroupAlarmFromDeepLinkMessage:
    'Se le recordará "{{alarmName}}" en {{alarmTime}} en {{alarmDate}}',
  joinedRepeatingGroupAlarmFromDeepLinkMessage: {
    zero: 'Se le recordatorio de "{{alarmName}}" {{repetitionString}}',
    one: 'Se le recordará "{{alarmName}}" {{repetitionString}} en {{alarmTime}} a partir de {{alarmDate}}'
  },
  youHaveSubscribedToThisAlarm:
    'Está siguiendo esta alarma desde {{creatorName}}.',
  alreadySharedAlarmDescription: {
    zero: 'No tienes seguidores para esta alarma.\n\nPuedes seguir compartiendo esta alarma con más personas utilizando un enlace. Cuando toquen el enlace, se creará una copia de esta alarma en su teléfono.',
    one: '{{count}} persona está siguiendo esta alarma.\n\nPuedes seguir compartiendo esta alarma con más personas utilizando un enlace. Cuando toquen el enlace, se creará una copia de esta alarma en su teléfono.',
    other:
      '{{count}} la gente sigue esta alarma.\n\nPuedes seguir compartiendo esta alarma con más personas utilizando un enlace. Cuando toquen el enlace, se creará una copia de esta alarma en su teléfono.'
  },
  shareParentAlarmDescription:
    'Está siguiendo esta alarma desde {{creatorName}}.\n\nTambién puedes compartir esta alarma con otras personas mediante un enlace. Cuando toquen el enlace, se creará una copia de esta alarma en su teléfono y también podrán empezar a seguirla.',
  shareableAlarmLinks: 'Enlaces compartibles',
  nPeopleFollowingAlarm: {
    zero: 'No tienes seguidores para esta alarma.',
    one: '{{count}} persona está siguiendo esta alarma.',
    other: '{{count}} la gente sigue esta alarma.'
  },
  likeUsOnFacebook: 'Facebook',
  followUsOnTwitter: 'Twitter',
  followUsOnInstagram: 'Instagram',
  youtube: 'YouTube',
  mediumBlog: 'Blogs en Medium',
  howtoVideos: 'Vídeos prácticos',
  followGalarmOnSocialMedia: 'Siga a Galarm en las redes sociales',
  galarmOnSocialMedia: 'Galarm en las redes sociales',
  followGalarmOnSocialMediaDescription:
    'Verás noticias interesantes sobre las actualizaciones de la aplicación, el uso de las funciones, las próximas funciones y las principales reseñas de la aplicación. También puedes interactuar con el equipo de Galarm.',
  sharedAlarm: 'Editar alarma compartida',
  editingPublishedAlarm: {
    one: 'Estás editando una alarma que has compartido anteriormente utilizando un enlace. {{count}} persona está siguiendo actualmente esta alarma.\n\nCuando modifiques esta alarma, se le notificarán los cambios.',
    other:
      'Estás editando una alarma que has compartido anteriormente mediante un enlace. {{count}} personas siguen actualmente esta alarma.\n\nCuando modifiques esta alarma, se les notificarán los cambios.'
  },
  editingSubscribedAlarm:
    'Estás siguiendo esta alarma desde {{creatorName}}. No recibirás ninguna actualización de esta alarma después de editarla.',
  viewAlarm: 'Ver alarma',
  view: 'Ver',
  editAlarm: 'Editar alarma',
  thanksForTryingPremiumTitle: 'Ya está todo listo.',
  thanksForTryingPremiumMessage:
    'Esperamos que disfrute de las funciones premium.\n\nSi tienes alguna pregunta, ponte en contacto con el servicio de asistencia a través de "Ajustes > Comentarios" o escríbenos a galarm@acintyo.com.',
  subscribedAlarmEditedInAlarmDetails:
    '{{creatorName}} ha cambiado su alarma.\n¿Actualizar su alarma?',
  subscribedAlarmDeletedInAlarmDetails:
    '{{creatorName}} ha borrado su alarma.\n¿Ha borrado su alarma?',
  updateSubscribedAlarm: 'Sí, actualizar',
  deleteSubscribedAlarm: 'Sí, borrar',
  ignoreSubscribedAlarm: 'No, ignora',
  customRepetitionPremiumScreenDescription:
    'La repetición personalizada es una función premium.\n\nPuede utilizar esta función para crear alarmas con repeticiones avanzadas. Algunos ejemplos son:\n',
  repeatAlternateDate: '✅ Repetir en días alternos.',
  repeatEveryNDay: '✅ Repetir cada 3 días',
  repeatEveryNHoursAndMMinutes: '✅ Repetir cada 2 horas 30 minutos',
  repeatEveryNMinutes: '✅ Repetir cada 45 minutos.',
  repeatBiweekly: '✅ Repetir cada 2 semanas',
  repeatEveryNWeeks: '✅ Repetir cada 6 semanas',
  repeatEveryNMonths: '✅ Repetir cada 3 meses (trimestral)',
  repeatEveryLastDayOfMonth: '✅ Repetir el último día de cada mes',
  repeatDayOfWeekOfMonth: '✅ Se repite el 2º Sábado de cada mes.',
  repeatLastDayOfWeekOfMonth: '✅ Repetir el último Viernes de cada mes.',
  customRepetition: 'Repetición personalizada',
  lastDayOfMonth: 'Último día de cada mes',
  dayOfWeekInMonth: '{{weekNumberOfDayInMonth}} {{dayName}} de cada mes',
  lastDayOfWeekInMonth: 'Último {{dayName}} de cada mes',
  dayOfWeekInMonthInYearSummary:
    '{{weekNumberOfDayInMonth}} {{dayName}} de {{monthName}}',
  lastDayOfWeekInMonthInYearSummary: 'Última {{dayName}} de {{monthName}}',
  lastDayOfMonthInYearSummary: 'Último día de {{monthName}}',
  dayOfWeekInMonthInYear:
    '{{weekNumberOfDayInMonth}} {{dayName}} de {{monthName}} cada año',
  lastDayOfWeekInMonthInYear: 'Última {{dayName}} de {{monthName}} cada año',
  lastDayOfMonthInYear: 'Último día de cada año en {{monthName}} ',
  chooseAMonthsConfiguration: 'Elija una configuración.',
  chooseADaysConfiguration: 'Elija una configuración',
  chooseAYearsConfiguration: 'Elija una configuración.',
  didntGetCodeNewUser:
    '¿No has recibido el código?\n\nPuedes solicitar de nuevo el código o Saltarte el registro por ahora. Puedes registrarte más tarde.',
  didntGetCodeExistingUser:
    '¿No has recibido el código?\n\nPuede volver a solicitar el código o cancelar la inscripción. Puede registrarse más tarde.',
  didntGetCodeUpdatePhoneNumber:
    '¿No has recibido el código?\n\nPuedes volver a solicitar el código o intentarlo más tarde.',
  alreadySentCodeAgain:
    'Le hemos reenviado el código. Si tienes dificultades para obtener el código, por favor, Salta el registro por ahora y regístrate más tarde.',
  theme: 'Tema',
  announceAlarmName: 'Anunciar título de alarma',
  ttsHelperText:
    'El título de la alarma se anunciará cuando ésta llame. Puede tocar el botón de reproducción situado delante del título de la alarma para escuchar una vista previa.',
  initializingTts: 'Inicialización de la conversión de texto a voz',
  errorSpeakingAlarmName:
    'No se puede pronunciar el título de la alarma. Ponte en contacto con el servicio de asistencia desde "Ajustes > Comentarios".',
  entityAlarm: 'alarma',
  entityGroup: 'grupo',
  entityContact: 'contacto',
  admin: 'Admin',
  specifyAlarmTitleToSpeak: 'Especifique el título de la alarma para hablar',
  wakeup: 'Despierta',
  ttsPremiumScreenDescription:
    'Anunciar el título de la alarma es una función premium.\n\nSi está activada, el título de la alarma se anunciará cuando suene la alarma.\n\nPara obtener una vista previa, introduzca un título de alarma y pulse Reproducir.',
  unknownAlarmCategory: 'Categoría de alarma desconocida',
  cantAnnounceAlarmTitle: 'El título de la alarma no se anunciará',
  ringtoneDurationIsSmall:
    'La duración del tono de llamada debe ser de al menos 5 segundos para que se anuncie el título de la alarma.\n\n¿Desea continuar con estos ajustes?',
  noActionItems: 'No hay puntos de acción.',
  personNotInContacts: 'Esta persona no está en tus contactos de Galarm.',
  light: 'Claro',
  dark: 'Oscuro',
  systemDefault: 'Usar ajuste del dispositivo',
  chooseTheme: 'Elegir tema',
  dontSeeAllYourContacts: '¿No ves todos tus contactos?',
  lastContactsSyncedAt: 'Última sincronización en {{dateString}}',
  refresh: 'Actualizar',
  forever: 'Para siempre',
  autoSnoozeConfiguration: 'Configurar Posponer',
  autoSnoozeConfigurationSummary:
    '{{autoSnoozeDurationLabel}}, {{autoSnoozeCountLabel}}',
  uncategorizedCategoryName: 'No categorizado',
  uncategorizedCategoryScreenTitle: 'Sin categoría',
  setAlarmCategory: 'Ajuste de la categoría de alarma',
  changeAlarmCategory: 'Cambiar categoría de alarma',
  viewAlarmHistory: 'Ver historial de alarmas',
  computingAlarmHistory: 'Resumen informático del historial de alarmas...',
  alarmByCategories: '{{name}} Alarmas',
  alarmCategories: 'Categorías',
  editAlarmCategory: 'Editar categoría',
  deleteAlarmCategory: 'Eliminar categoría',
  newAlarmCategoryTitle: 'Nueva categoría',
  newAlarmCategoryNamePlaceholder: 'Especifique un nombre',
  editAlarmCategoryTitle: 'Editar categoría',
  alarmCategorySummary: {
    zero: '{{alarmsSummary}}',
    one: '{{alarmsSummary}}, {{count}} falló',
    other: '{{alarmsSummary}}, {{count}} falló'
  },
  numAlarms: {
    zero: 'Sin alarmas',
    one: '{{count}} alarma',
    other: '{{count}} alarmas'
  },
  numIncidents: {
    zero: 'Ningún incidente',
    one: '{{count}} incidente',
    other: '{{count}} incidentes'
  },
  openedIncidents: 'Incidentes abiertos',
  closedIncidents: 'Incidentes cerrados',
  incidentChart: 'Gráfico de incidencias (últimos 30 días)',
  incidentCalendar: 'Calendario de incidencias',
  incidentDistribution: 'Distribución de incidentes (últimos 30 días)',
  noIncidentsOpenedClosedInLast30Days:
    'Ningún incidente abierto o cerrado en los últimos 30 días',
  noIncidentsOpenedInLast30Days:
    'Ningún incidente abierto en los últimos 30 días',
  color: 'Color',
  createNewAlarmCategory:
    'Cree una nueva categoría pulsando el botón + en la parte inferior derecha',
  setValidName: 'Ajuste un nombre válido',
  specifyTimezoneForAlarmMessage:
    'Puede especificar una zona horaria al crear alarmas. Las alarmas llamarán según la zona horaria especificada. \n\nSi selecciona "Zona horaria del dispositivo", las alarmas llamarán según la zona horaria de su dispositivo.',
  alarmCategoryFeatureDescription:
    'Crea tus propias categorías para las alarmas. Las categorías te permiten organizar fácilmente tus alarmas.',
  markAlarmDoneInAdvance: '¿Marcar alarma "Hecho" antes de tiempo?',
  skipAlarmInAdvance: '"¿Saltar" la alarma antes de tiempo?',
  markAlarmDoneInAdvanceMessageRepeatingAlarm:
    'La alarma no llamará a {{dateTimeString}} si marca "Hecho" ahora.',
  skipAlarmInAdvanceMessageRepeatingAlarm:
    'La alarma no llamará a {{dateTimeString}} si la saltas ahora.',
  markAlarmDoneInAdvanceMessageOneTimeAlarm:
    'La alarma no llamará a {{dateTimeString}} si marca "Hecho" ahora.',
  skipAlarmInAdvanceMessageOneTimeAlarm:
    'La alarma no llamará a {{dateTimeString}} si la saltas ahora.',
  alarmsByCategories: 'Alarmas por categorías',
  workAlarmCategory: 'Trabajo',
  homeAlarmCategory: 'Inicio',
  birthdaysAlarmCategory: 'Cumpleaños',
  more: 'Más',
  alarmCategory: 'Categoría de alarma',
  editAlarmCategoryColor: 'Editar color',
  editAlarmCategoryColorTitle: 'Editar color para {{name}}',
  allUserCategoriesDeleted:
    'Se han eliminado todas las categorías de alarma creadas por usted porque se ha cancelado la suscripción premium. Las alarmas de esas categorías aparecerán en "No categorizadas".',
  calendar: 'Calendario',
  eventExportedFromGalarm:
    '"Exportado de Galarm en {{dateString}} en {{timeString}}."',
  unableToExportEventToCalendar:
    'No se puede exportar el evento al calendario. Asegúrate de que has dado permiso para acceder al calendario en el dispositivo. Envíanos tus comentarios a través de "Ajustes > Comentarios" si el problema persiste.',
  scanQrCode:
    'Para acceder a Galarm en un navegador, vaya a web.galarm.app y escanee el código QR.',
  logOut: 'Cerrar sesión',
  useHere: 'Utilizar aquí',
  appRunningElsewhere: 'Ha salido de esta sesión. ¿Desea reanudar la sesión?',
  dateTime: 'Fecha y hora',
  selectAtLeastOneDayForRepetition:
    'Elija al menos un día en el que deba repetirse la alarma.',
  chooseNumberOfDaysForAlarmRepetition:
    'Introduzca el número de días tras los cuales debe repetirse la alarma.',
  chooseNumberOfWeeksForAlarmRepetition:
    'Introduzca el número de semanas tras las cuales debe repetirse la alarma.',
  chooseNumberOfMonthsForAlarmRepetition:
    'Introduzca el número de meses tras los cuales debe repetirse la alarma.',
  chooseNumberOfYearsForAlarmRepetition:
    'Introduzca el número de años tras los cuales debe repetirse la alarma.',
  previouslySelectedContactsAreUnselected:
    'Los contactos seleccionados anteriormente se deseleccionan.',
  previouslySelectedGroupIsUnselected:
    'El grupo seleccionado anteriormente no está seleccionado.',
  galarmWebInstructions:
    '1. Abre la aplicación Galarm en tu teléfono\n2. Ve a la pestaña "Ajustes" y toca en "Galarm Web"\n3. Por favor, asegúrese de que está ejecutando la última aplicación si no ve la opción\n4. Escanea el código QR de la derecha',
  likeToCreateAlarm: 'Crear una alarma para...',
  payUtilityBills: 'Pagar facturas',
  wakeupAlarmName: 'Despierta',
  exerciseAlarmName: 'Ejercicio',
  drinkWaterAlarmName: 'Beber agua',
  everyNHoursRepetitionStringShortSummary: {
    one: '{{startTimeString}} a {{endTimeString}} Cada hora',
    other: '{{startTimeString}} a {{endTimeString}} Cada {{count}} horas'
  },
  firstTimeManagementQuote:
    '"La mala noticia es que el tiempo vuela. La buena noticia es que tú eres el piloto".',
  firstTimeManagementQuoteAuthor: 'Michael Altshuler',
  secondTimeManagementQuote:
    '"Toda gestión del tiempo empieza por la planificación".',
  secondTimeManagementQuoteAuthor: 'Tom Greening',
  thirdTimeManagementQuote:
    '"Nunca dejes para mañana lo que puedas hacer hoy".',
  thirdTimeManagementQuoteAuthor: 'Benjamin Franklin',
  fourthTimeManagementQuote:
    '"El tiempo es lo que más queremos, pero lo que peor utilizamos".',
  fourthTimeManagementQuoteAuthor: 'William Penn',
  fifthTimeManagementQuote:
    '"Dentro de un año quizá desearías haber empezado hoy".',
  fifthTimeManagementQuoteAuthor: 'Karen Lamb',
  sixthTimeManagementQuote: '"Puedes retrasarlo, pero el tiempo no lo hará".',
  sixthTimeManagementQuoteAuthor: 'Benjamin Franklin',
  tapToGetStarted: 'Pulsa "+" para empezar.',
  allCategories: 'Todas las categorías',
  createAlarmWithContact: 'Crear una alarma con {{name}}',
  createAlarmWithGroup: 'Crear una alarma con {{name}}',
  editGroup: 'Editar grupo',
  discardChanges: 'Los cambios actuales se descartarán. ¿Continuar?',
  alarmWillRingEveryNDays: {
    zero: 'La alarma llamará todos los días.',
    one: 'La alarma llamará en días alternos.',
    other: 'La alarma llamará cada día {{dayWithOrdinal}}.'
  },
  hoursString: { zero: '', one: '1 hora', other: '{{count}} horas' },
  minutesString: { zero: '', one: '1 minuto', other: '{{count}} minutos' },
  alarmWillRingEveryMHoursAndNMinutes:
    'La alarma llamará después de cada {{repeatString}} empezando por {{dayString}}. Después, {{otherDaysString}} y así sucesivamente.',
  alarmWillRingEveryNWeeks: {
    zero: 'La alarma llamará cada semana.',
    one: 'La alarma llamará cada dos semanas.',
    other: 'La alarma llamará cada semana {{weekWithOrdinal}}.'
  },
  alarmWillRingEveryNMonths: {
    zero: 'La alarma llamará cada mes.',
    one: 'La alarma llamará cada dos meses.',
    other: 'La alarma llamará cada mes {{monthWithOrdinal}}.'
  },
  alarmWillRingEveryNYears: {
    zero: 'La alarma llamará todos los años.',
    one: 'La alarma llamará cada dos años.',
    other: 'La alarma llamará cada {{yearWithOrdinal}} año.'
  },
  ringParticipantAlarmsByDefaultTooltipMessage:
    'Si esta opción está activada, las alarmas de los demás sonarán sin que las aceptes. Aun así, debes aceptar la alarma para que el creador sepa que tienes intención de participar en ella.\n\nCuando esta preferencia está desactivada, las alarmas que otros añadan para ti no sonarán en tu teléfono a menos que las aceptes explícitamente.',
  specifyTimezoneForAlarmDefaultTooltipMessage:
    'Cuando esta preferencia está activada, puede especificar una zona horaria al crear alarmas. Las alarmas llamarán según la zona horaria especificada.\n\nSi esta opción está desactivada, las alarmas llamarán según la zona horaria del dispositivo.',
  ringOnEarphoneOnlyTooltipTitle:
    'Comportamiento de la alarma cuando se conectan los auriculares',
  ringOnEarphoneOnlyTooltipMessage:
    'Cuando los auriculares están conectados, puedes configurar tus alarmas para que llamen sólo en los auriculares o en el altavoz del teléfono y en los auriculares.\n\nSi los auriculares no están conectados, las alarmas llamarán por el altavoz del teléfono.',
  gestureOptionsTooltipTitle: 'Gesto para descartar una alarma',
  gestureOptionsTooltipMessage:
    'Cuando suena una alarma en la pantalla de bloqueo, puedes descartarla pulsando un botón o deslizándolo.\n\nEste ajuste te permite elegir cómo quieres descartar una alarma.',
  notificationSettingsTooltipTitle: 'Ajustes de notificación',
  notificationSettingsTooltipMessage:
    'Activa las notificaciones que quieras recibir y desactiva las que no te interesen.',
  messagesForAlarm: 'Mensajes para {{name}}',
  systemNotificationsTooltipMessage:
    'Notificaciones generadas por la aplicación para eventos como:\n  - cuando las alarmas se ven afectadas debido a que el contacto cambia de zona horaria\n  - cuando un contacto invitado se registra en Galarm\n  - cuando las alarmas se ven afectadas porque alguien elimina su cuenta',
  galarmWebNotificationsTooltipMessage:
    'Notificaciones enviadas a usted desde Galarm Web.',
  alarmsfromOthersNotificationsTooltipMessage:
    'Notificaciones que se envían cuando se le añade a una alarma como participante.',
  alarmChatNotifictionsTooltipMessage:
    'Notificaciones enviadas para mensajes de chat en una alarma.',
  groupNotificationsTooltipMessage:
    'Notificaciones que se envían cuando se te añade a un grupo, se te elimina de un grupo o se añade/elimina a alguien de un grupo.',
  alarmAcknowledgementNotificationsTooltipMessage:
    'Notificaciones que se envían cuando un participante reconoce una alarma en la que usted también es participante.',
  sharedAlarmNotificationsTooltipMessage:
    'Notificaciones que se envían\n  - cuando alguien se suscribe a una alarma compartida por usted a través de un enlace\n  - cuando se modifica una alarma a la que está suscrito',
  sleepTimer: 'Música para dormir',
  sleepTimerTitle: 'Reproducir música para',
  ongoingSleepTimer: 'Música para dormir En curso',
  stopSleepTimer: '¿Quieres parar la música actual?',
  restartSleepTimer: '¿Quieres parar la música actual y empezar una nueva?',
  play: 'Jugar',
  youAreOffline:
    'Actualmente está desconectado. Conéctese para realizar cualquier operación.',
  sleepMusicTooltip:
    'Reproduce sonidos naturales para relajar el cuerpo y la mente. La música deja de sonar tras la duración especificada.',
  alarmLinkCopiedToClipboard: 'Enlace de alarma copiado en el portapapeles.',
  confirmLogOut: '¿Quieres cerrar la sesión?',
  appNotRunningMessage:
    'Hemos detectado que Galarm no se está ejecutando en tu teléfono. Pulse para iniciar Galarm y no perder ninguna alarma importante.',
  tapToEnterNotesForAlarm: 'Pulse para introducir notas',
  accountAlreadyExists: 'Cuenta existente encontrada',
  accountsMerged:
    'Hemos encontrado una cuenta existente asociada a este número de móvil. Hemos fusionado las dos cuentas.',
  upgradeToPremiumToAssignUserDefinedCategory:
    'Actualización a Premium para seleccionar la categoría definida por el usuario.',
  userDefinedCategoryNotSetForAlarm:
    'Actualización a Premium para autoasignar la categoría definida por el usuario. La categoría no está ajustada para la alarma.',
  alarmDetails: 'Detalles de la alarma',
  profileDetails: 'Perfil',
  activeWebSession:
    'Ya tienes una sesión web activa. Puede salir de ella o iniciar una nueva sesión.\n\nAl iniciar una nueva sesión, se cerrará automáticamente la sesión activa en ese momento.',
  startNewSession: 'Iniciar una nueva sesión',
  confirmWebSessionLogout:
    'Saldrá de la sesión actual. Puede iniciar una nueva sesión volviendo a escanear el código.',
  defaultInstantAlarmTitleForGroup: '{{groupName}} Alerta de {{creatorName}}',
  defaultInstantAlarmTitleForContact: 'Alerta de {{creatorName}}',
  atleastOneMemberShouldBeActive: 'Al menos un participante debe estar activo.',
  activeParticipants: 'Participantes activos',
  acceptAlarm: 'Aceptar alarma',
  rejectAlarm: 'Rechazar alarma',
  nConfirmed: '{{count}} Confirmado',
  nDeclined: '{{count}} Rechazado',
  nPending: '{{count}} pendiente',
  everyoneConfirmed: 'Todos han confirmado',
  everyoneDeclined: 'Todos han Rechazado',
  appTagline: 'No se pierda nada',
  usePreviousBackup: 'Soy un usuario registrado >',
  viewAlarmsByCategories: 'Ver alarmas por categorías',
  viewAllAlarms: 'Ver todas las alarmas',
  switchToSummaryView: 'Cambiar a la Ver Resumen',
  switchToListView: 'Cambiar a la Ver Lista',
  hideHourlyAlarms: 'Ocultar alarmas horarias',
  calendarView: 'Calendario Galarm',
  viewRecentlyDeletedAlarms: 'Ver alarmas borradas recientemente',
  deletedOnDate: 'Suprimido el {{alarmDate}}',
  recentlyDeletedAlarmFooterText: 'Esta alarma se ha borrado el {{alarmDate}}',
  noRecentlyDeletedAlarms: 'No hay alarmas borradas recientemente',
  restoreAlarmsFromMobileApp:
    'Estas alarmas se borrarán automáticamente transcurridos 30 días desde su eliminación. Si desea restaurar alguna de ellas, vaya a la pantalla "Alarmas eliminadas recientemente" de su aplicación móvil y pulse sobre su icono de restauración.',
  deleteInactiveAlarms: 'Borrar alarmas inactivas',
  defaultConfigurationUpdated:
    'Se ha actualizado la configuración por defecto.',
  notAlerted: '(excluidos)',
  alarmKeepsRingingHelp: 'Ayuda Mi alarma no para de llamar',
  alarmKeepsRingingHelpMessage:
    'Hemos actualizado tus alarmas y enviado el informe del problema.',
  alarmKeepsRingingHelpAlert:
    'Actualizaremos sus alarmas y ya no debería observar este problema. También enviaremos un informe del problema a nuestro equipo de asistencia para que lo investigue.\n\n  Si el problema persiste, ponte en contacto con nosotros a través de "Ajustes > Comentarios".',
  sendingFeedback: 'Envío de comentarios',
  sendingProblemReport: 'Envío de informe de problema',
  fixingAlarms: 'Actualizar las alarmas',
  existingAccountFound: 'Copia de seguridad restaurada',
  existingAccountDataRestored: {
    zero: 'Enhorabuena, todos los datos han sido restaurados desde el apoyo.',
    one: 'Enhorabuena, todos los datos han sido restaurados desde el apoyo.',
    other: 'Enhorabuena, todos los datos han sido restaurados desde el apoyo.'
  },
  noExistingAccountFound: 'No se ha encontrado apoyo',
  createdNewAccount:
    'Este número de teléfono no está registrado en Galarm. Hemos creado una nueva cuenta con este número de teléfono.',
  iosCloseToAllowedNumNotificationsScheduled: {
    zero: 'iOS permite que una aplicación programe hasta 64 notificaciones. Has utilizado todas esas notificaciones. Es posible que te pierdas algunas alarmas.',
    one: 'iOS permite que una aplicación programe hasta 64 notificaciones. Has utilizado {{numNotifications}} de esas notificaciones.'
  },
  acknowledgeAlarmToScheduleNextOccurrence:
    'Esta es la última notificación para esta alarma. Mantenga pulsada esta notificación y realice una acción para programar la siguiente aparición de esta alarma.',
  lastNotificationForOneTimeAlarm:
    'Esta es la última notificación para esta alarma.',
  criticalAlertsMessageTitle:
    'Llama a las alarmas cuando el teléfono esté en silencio',
  criticalAlertsMessageDescription:
    '\nPuede configurar Galarm para que utilice las Alertas críticas de iOS para llamar a las alarmas cuando su teléfono esté silenciado. ¿Desea activarlo para todas las alarmas?\n\nTambién puedes habilitarlo de forma selectiva para algunas alarmas editando su ajuste "Sonido y vibración > Alertas críticas".',
  allowCriticalAlerts: 'Permitir alertas críticas',
  criticalAlertsPermissionDenied:
    'Galarm requiere el permiso de su teléfono para mostrar alertas críticas. Por favor, activa este permiso en los ajustes de tu teléfono.',
  enableCriticalAlertsForAllAlarms: 'Sí, activado para todas las alarmas',
  selectivelyEnableCriticalAlerts: 'No, voy a habilitar selectivamente',
  firstName: 'Nombre',
  lastName: 'Apellido',
  email: 'Correo electrónico',
  password: 'Contraseña',
  confirmPassword: 'Confirmar contraseña',
  signup: 'Inscribirse',
  login: 'Inicio de sesión',
  alreadySignedUp: '¿Ya se ha inscrito?',
  notAUser: '¿No es usuario?',
  subscribe: 'Suscríbase a',
  choosePlan: 'Configurar plan',
  billingCycle: 'Ciclo de facturación',
  needHelpToGetStarted: '¿Necesita ayuda para empezar?',
  browser: 'Navegador: {{name}}',
  deviceOs: 'Dispositivo: {{name}}',
  lastActive: 'Último activo: {{timestamp}}',
  signUp: 'Inscríbete',
  useGalarmOnWeb: 'Utilizar Galarm en la Web',
  welcomeUser: 'Bienvenido {{name}}',
  changePassword: 'Cambiar contraseña',
  saveAlarm: 'Guardar alarma',
  galarmUserTip: '¿Lo sabías?',
  sendAlarm: 'Enviar alarma',
  saveGroup: 'Guardar grupo',
  webAppBestViewedOnDesktop:
    'La URL (web.galarm.app) sirve para iniciar una interfaz de Galarm en un navegador web de su ordenador portátil o de sobremesa.\n\nPara utilizar Galarm en este dispositivo móvil, instale la aplicación para Android desde Google Play o la aplicación para iOS desde App Store utilizando uno de los enlaces siguientes:',
  scanQrCodeMessage: 'Escanear código QR',
  reloadQrCode: 'Haga clic para recargar el código QR',
  invalidQrCodeScanned: 'Código QR no válido escaneado.',
  unableToScanCode: 'No se puede escanear el código',
  unableToScanCodeMessage:
    'Ha habido un problema al escanear el código. Por favor, inténtalo de nuevo. Si sigues teniendo problemas, envíanos tus comentarios desde la aplicación móvil a través de "Ajustes > Comentarios".',
  problemWithEnterpriseAccountSetup:
    'Se ha producido un problema en el ajuste de su cuenta Galarm Pro. Póngase en contacto con el servicio de asistencia técnica.',
  problemWithCreatingCheckoutSession:
    'Se ha producido un problema al crear una sesión de pago. Póngase en contacto con el servicio de asistencia técnica.',
  problemWithCreatingCustomerPortalSession:
    'Se ha producido un problema al crear una sesión en el portal de clientes. Póngase en contacto con el servicio de asistencia técnica.',
  problemWithCreatingApiKey:
    'Se ha producido un problema al crear una clave API. Póngase en contacto con el servicio de asistencia técnica.',
  creatingAnAccount: 'Crear una cuenta',
  currentPlan: 'Plan actual: {{name}} ({{quantity}} escaños)',
  myAccount: 'Información sobre la cuenta',
  new: 'Nuevo',
  deleteAllAlarmHistoryAlertTitle: 'Borrar historial de alarmas',
  deleteAllAlarmHistoryAlertMessage:
    'Pulse "Continuar" para borrar el historial de esta alarma. Esta acción no se puede deshacer.',
  ageOutInactiveAlarmsMessage:
    'Tienes {{count}} alarmas que llevan inactivas más de 60 días. Tener muchas alarmas inactivas puede afectar al rendimiento de la aplicación.\n\nQuieres eliminar estas alarmas?',
  duplicateAlarm: 'Duplicar alarma',
  graduallyIncreaseVolume: 'Aumentar gradualmente el volumen',
  graduallyIncreaseVolumeTooltipMessage:
    'Cuando esta opción está activada, el volumen de la alarma aumenta gradualmente hasta alcanzar el volumen del tono de llamada configurado. Esto permite detener la alarma antes de que suene demasiado fuerte.\n\nCuando esta preferencia está desactivada, las alarmas llaman al volumen del tono de llamada configurado durante toda la duración del tono de llamada.',
  teams: 'Equipos',
  alerts: 'Alertas',
  listeners: 'Oyentes',
  noMembersConfiguredInEnterpriseAccount:
    'Aún no has añadido ningún miembro. Los miembros son usuarios que pueden ser añadidos a la política de escalada de un oyente y son alertados de los incidentes recibidos por el oyente. Estas personas ya deberían estar registradas en Galarm.',
  noTeamsConfiguredInEnterpriseAccount:
    'Aún no ha creado ningún equipo. Los equipos son grupos de miembros que pueden ser añadidos a la política de escalado de un receptor y son alertados de los incidentes recibidos por el receptor.',
  noAlertsConfiguredInEnterpriseAccount:
    'Aún no ha creado ningún oyente. Puede configurar escuchas para que escuchen las incidencias notificadas por las herramientas de monitorización. Una vez creada una escucha, puede ver instrucciones sobre cómo integrar la escucha con varias herramientas de monitorización.\n\nPuede configurar varios niveles de escalado para cada escucha.',
  noShiftsConfiguredInEnterpriseAccount:
    'Aún no has creado ninguna configuración de turnos.',
  noRotationsConfiguredInEnterpriseAccount:
    'Aún no has creado ninguna rotación.',
  rotations: 'Rotaciones',
  addRotation: 'Añadir rotación',
  editRotation: 'Ver rotación',
  enterRotationName: 'Introduzca el nombre de la rotación',
  rotationType: 'Tipo de rotación',
  numberOfHoursInRotation: 'Número de horas de rotación',
  numberOfDaysInRotation: 'Número de días de rotación',
  numberOfWeeksInRotation: 'Número de semanas de rotación',
  specify: 'Especifica...',
  noIntegrationsConfiguredInEnterpriseAccount:
    'Aún no ha configurado ninguna configuración de integración.',
  loadingUser: 'Carga de la información del usuario',
  addMember: 'Añadir miembro',
  add: 'Añadir',
  noUserFoundWithMobileNumber:
    'No se ha encontrado ningún usuario registrado de Galarm con este número de teléfono. Por favor, asegúrese de introducir el número de teléfono con el código de país sin caracteres especiales como paréntesis, guiones, espacios en blanco, etc.',
  memberWithPhoneNumberAlreadyAdded:
    'El miembro con este número de teléfono ya ha sido añadido.',
  memberWithEmailAlreadyAdded:
    'El miembro con este correo electrónico ya ha sido añadido.',
  assignKeyToMember: 'Asignar clave de licencia',
  revokeKeyFromMember: 'Revocar la clave de licencia',
  cantAssignKeyToMember: 'No se puede asignar una clave a un miembro',
  noEnterpriseLicenseKeyAvailable:
    'No hay claves de licencia disponibles. Actualice su plan.',
  addTeam: 'Añadir equipo',
  enterTeamName: 'Introduzca un nombre de equipo',
  addAlert: 'Añadir oyente',
  low: 'Bajo',
  medium: 'Medio',
  high: 'Alta',
  severity: 'Gravedad',
  enterAlertName: 'Introduzca el nombre del receptor',
  organization: 'Organización',
  startTime: 'Hora de inicio',
  endTime: 'Hora final',
  shiftName: 'Turno {{index}}',
  addShift: 'Añadir Turno',
  assignees: 'Cesionarios',
  shiftStartEndTime: '{{startTime}} - {{endTime}}',
  assignee: 'Cesionario',
  specifyAssigneeForShift: 'Especificar la persona asignada para el turno',
  selectAtLeastOneMember: 'Seleccione al menos un miembro',
  editMember: 'Editar miembro',
  editTeam: 'Equipo de edición',
  cantRemoveMemberAssignedToTeamsOrAlerts:
    'El miembro forma parte de un equipo o está asignado a un oyente. Por favor, elimina al miembro de esos equipos/oyentes antes de borrarlo.\nEquipos: {{teams}}\nOyentes: {{alerts}}',
  cantRevokeKeyFromMemberAssignedToTeamsOrAlerts:
    'El miembro forma parte de un equipo o está asignado a un oyente. Por favor, retira al miembro de esos equipos/oyentes antes de revocar la clave.\nEquipos: {{teams}}\nOyentes: {{alerts}}',
  cantDeleteTeamAssignedToAlerts:
    'El equipo forma parte de una escucha. Por favor, elimine el equipo del oyente antes de borrarlo.\nOyentes: {{alerts}}',
  cantDeleteRotationAssignedToAlerts:
    'La rotación forma parte de una escucha. Por favor, elimine el equipo del oyente antes de borrarlo.\nOyentes: {{alerts}}',
  editAlert: 'Editar oyente',
  timezoneDisplayString: '(GMT{{offset}}) {{timezone}}',
  apiKey: 'Clave API',
  viewApiKey: 'Ver clave API',
  apiKeyWarningMessage:
    'Mantenga su clave API segura en todo momento. No la compartas con nadie. Si cree que su clave API ha sido comprometida, pulse el botón "Cambiar" de arriba para cambiarla. Después de cambiar la clave, tendrá que especificar la nueva clave en todos los lugares en los que esté activando incidentes mediante la API REST',
  integrationConfigs: 'Configuraciones de integración',
  addIntegrationConfig: 'Añadir configuración de integración',
  editIntegrationConfig: 'Editar configuración de integración',
  integrationSource: 'Fuente de integración',
  apiToken: 'Token API',
  integrations: 'Integraciones',
  selectPlaceholder: 'Seleccione...',
  zendeskSubdomain: 'Subdominio de Zendesk',
  creatorTimeForBuddyAlarm: '{{timeString}} ({{remindMeAfter}})',
  nextFiveAlarms: '5 alarmas siguientes',
  categories: 'Categorías',
  viewingAlarmsDueToday: 'Ver las alarmas que vencen hoy',
  viewingAlarmsDueTomorrow: 'Ver alarmas para mañana',
  expiredAlarms: 'Alarmas caducadas',
  viewingExpiredAlarms: 'Ver alarmas caducadas',
  noAlarmsDueToday: 'No hay alarmas para hoy',
  noAlarmsDueTomorrow: 'No hay alarmas para mañana',
  noExpiredAlarms: 'No hay alarmas caducadas',
  viewingAllAlarms: 'Ver todas las alarmas',
  viewingRecentlyDeletedAlarms: 'Ver alarmas borradas recientemente',
  summaryView: 'Pruebe la Ver Resumen?',
  summaryViewDescription:
    'Ver alarmas organizadas por fechas de vencimiento y categorías.\n\nPuedes volver a la vista de lista con el botón ⋮ de la parte superior derecha.',
  disableBatteryOptimizationMessageOnStartup:
    'Galarm necesita ejecutarse en segundo plano para que las alarmas llamen de forma fiable. Por favor, permita el permiso en la siguiente pantalla.',
  disableBatteryOptimizationMessage:
    'Galarm debe ejecutarse en segundo plano para que las alarmas llamen de forma fiable.',
  allowNotificationsToDisplayAlarms:
    'Galarm necesita permiso para mostrar notificaciones cuando llaman las alarmas.',
  scheduleExactAlarmsMessage:
    'Galarm necesita permiso para programar alarmas exactas.',
  alarmCategoryWithSameNameExists:
    'Ya existe una categoría con este nombre. Por favor, especifique un nombre diferente.',
  alarmRepetitionsPremiumFeature: 'Opciones avanzadas de repetición',
  ringtonesPremiumFeature: 'Más tonos de llamada',
  galarmWebPremiumFeature: 'Utilizar Galarm en la Web',
  taskListPremiumFeature: 'Crear varias listas de tareas',
  preReminderPremiumFeature: 'Recordatorio previo para alarmas',
  alarmCategoriesPremiumFeature: 'Categorías de alarma personalizadas',
  shareableAlarmLinksPremiumFeature: 'Enlaces de alarma compartibles',
  announceAlarmNamePremiumFeature: 'Anunciar título de alarma',
  manageSubscription: 'Gestionar suscripción',
  purchaseRestored: 'Compra restaurada.',
  unableToRetrieveSubscriptionPlansAndroid:
    'No podemos conectarnos a Google Play para realizar la actualización. Asegúrate de que estás conectado a Internet y vuelve a intentarlo. \n\nPor favor, informe de un problema a nuestro equipo de soporte, si este problema persiste.',
  unableToRetrieveSubscriptionPlansIos:
    'No podemos conectarnos a la App Store para realizar la actualización. Asegúrate de que estás conectado a Internet e inténtalo de nuevo. \n\nSi el problema persiste, comunícalo a nuestro equipo de asistencia.',
  drawOverOtherApps: 'Visualización sobre otras aplicaciones',
  drawOverOtherAppsHelperText:
    'Permitir que Galarm muestre las alertas de alarma en la parte superior',
  nTimes: { one: 'Una hora', other: '{{count}} veces' },
  learnHowToUseFocus: 'Aprender Focus',
  enhancedNotificationsDeprecated: 'Notificaciones mejoradas obsoletas',
  enhancedNotificationsDeprecatedMessage:
    'Hemos descubierto que las notificaciones mejoradas no son fiables y provocan un consumo excesivo de batería. Utilice iOS Focus para permitir que Galarm llame a las alarmas cuando el teléfono esté en No molestar.',
  usingIosFocus: 'Uso de iOS Focus',
  iosFocusModePara1:
    'Galarm no llamará cuando el interruptor de timbre del teléfono esté apagado o cuando el volumen del timbre sea 0.\n\nApple recomienda utilizar "Enfoque" cuando desee mantener el teléfono en silencio pero permitir que algunas aplicaciones le notifiquen. Sigue las siguientes instrucciones para configurar Focus en tu teléfono.',
  iosFocusModePara2:
    'Puedes ver instrucciones más detalladas con capturas de pantalla en https://galarm.zendesk.com/hc/en-us/articles/10906890793236-Allow-alarms-to-ring-on-silent-on-iOS.',
  settingFocus: 'Ajuste del enfoque:',
  settingFocusFootNotes:
    'Ten en cuenta que Focus está disponible a partir de iOS 15.\n\nSi tienes preguntas o comentarios, ponte en contacto con nosotros a través de "Ajustes > Comentarios".',
  enablingFocus: 'Enfoque habilitador:',
  settingFocusInstruction1: 'Vaya a "Ajustes del teléfono > Enfoque".',
  settingFocusInstruction2: 'Pulse sobre el perfil de enfoque "No molestar".',
  settingFocusInstruction3: 'Pulsa en "Aplicaciones" y añade Galarm.',
  settingFocusInstruction4:
    'Haz lo mismo con otros perfiles de Enfoque que hayas ajustado.',
  enablingFocusInstruction1:
    'Desliza el dedo hacia abajo desde el borde superior derecho para ver el Centro de control.',
  enablingFocusInstruction2: 'Pulse sobre Enfoque.',
  enablingFocusInstruction3:
    'Pulse No molestar o cualquier otro perfil de Enfoque que desee activar.',
  enablingFocusInstruction4:
    'Aparece seleccionado el Enfoque actualmente activado. Puede volver a pulsarlo para desactivarlo.',
  startDateForRotation: 'Fecha de inicio de la rotación',
  startTimeForRotation: 'Hora de inicio de la rotación',
  member: 'Miembro',
  multipleShiftsInADay: 'Varios turnos al día',
  escalationPolicy: 'Política de escalada',
  atTimeOfEvent: 'En la hora del suceso',
  afterNMinutes: 'Después de {{count}} minutos',
  afterNHours: {
    one: 'Después de 1 hora',
    other: 'Después de {{count}} horas'
  },
  afterDuration: 'En {{durationString}}',
  addTrigger: 'Añadir activador',
  viewCode: 'Ver Código',
  apiCodes: 'Códigos API',
  viewIncidents: 'Ver incidencias',
  incidentsForAlert: 'Incidencias para el oyente {{alertName}}',
  timestamp: 'Marca de tiempo',
  acknowledged: 'Reconocido',
  noIncidentsForAlert: 'Aún no hay incidentes para este oyente',
  apiCodeCopiedToClipboard: 'El código API se ha copiado en el portapapeles.',
  triggerName: 'Disparador {{index}}',
  delay: 'Retraso',
  chooseAssigneePlaceholder: 'Seleccione Cesionario...',
  delayForTrigger: 'Retraso del disparo',
  assigneeWillBeAlertedAfterDelay:
    'Se avisará al cesionario después de {{durationString}}.',
  escalationForIncidentsForAlert:
    'Escalada por incidente en {{incidentDate}} para el oyente {{alertName}}',
  alertIncidentAcknowledged: 'Reconocido por {{memberName}} en {{dateString}}',
  alertIncidentRecovered: 'Recuperado en {{dateString}}',
  alertIncidents: 'Incidentes',
  viewingAlertIncidents: 'Ver incidentes',
  createOrganization: 'Crear organización',
  youAreNotPartOfAnyOrganization:
    'No formas parte de ninguna organización. Crea una organización para empezar.',
  tellUsAboutYourOrganization: 'Háblenos de su organización',
  noActiveSubscription: 'No tienes una suscripción activa.',
  chooseOrganization: 'Elija una organización para continuar.',
  noTriggersForIncident:
    'No se han encontrado desencadenantes para este incidente.',
  role: 'Papel',
  viewer: 'Visor',
  newOrganization: 'Nueva Organización',
  noOrganizations: 'No formas parte de ninguna organización.',
  maxOrganizationsReached:
    'El usuario puede formar parte de un máximo de 10 organizaciones.',
  yourOrganizations: 'Organizaciones',
  urlNotFound: 'La URL no coincide con ninguna ruta admitida.',
  triggerAlert: 'Incidente desencadenante',
  recaptchaFailed: 'Error en la verificación reCAPTCHA. {{error}}',
  loadingAccountInformation: 'Carga de la información de la cuenta',
  loadingAuthInfo: 'Carga de la información de autenticación',
  allAlerts: 'Todos los oyentes',
  recentlyTriggeredAlerts: 'Receptores activados recientemente',
  recentlyClosedIncidents: 'Incidentes cerrados recientemente',
  noRecentlyTriggeredAlerts: 'No se han activado escuchas recientemente.',
  noRecentlyClosedIncidents: 'No se ha cerrado ningún incidente recientemente.',
  closedAt: 'Cerrado en {{dateTimeString}}',
  dashboard: 'Cuadro de mandos',
  loggedIntoOrganizationAccount:
    '(organización: {{organizationName}}, función: {{role}})',
  viewIncidentEscalation: 'Ver Escalada de incidentes',
  closeIncident: 'Incidente cercano',
  closingIncident: 'Incidente final',
  unableToRefreshEnterpriseAccount: 'No se ha podido actualizar su cuenta.',
  viewAuthToken: 'Ver Auth Token',
  problemWithCreatingAuthToken:
    'Se ha producido un problema al crear un autentificador. Póngase en contacto con el servicio de asistencia técnica.',
  authToken: 'Token de autenticación',
  authTokenWarningMessage:
    'Mantenga este token de autenticación seguro en todo momento. No la compartas con nadie. Si cree que su clave de API ha sido comprometida, pulse el botón "Cambiar" de arriba para cambiarla. Después de cambiar el token, tendrá que especificar el nuevo token en todos los lugares en los que active incidentes utilizando la API REST',
  authTokenCopiedToClipboard:
    'El token de autenticación se ha copiado en el portapapeles.',
  resendVerificationEmail: 'Reenviar correo de verificación',
  loggedInUsingEmail:
    'Inició sesión utilizando el correo electrónico {{email}}.',
  checkingIfMemberIsAppUser:
    'Comprobar si el miembro es usuario de la aplicación.',
  settingMemberDetails: 'Ajuste de los datos de los miembros.',
  addingTeam: 'Añadiendo equipo.',
  editingTeam: 'Equipo de edición.',
  addingRotation: 'Añadir rotación.',
  editingRotation: 'Rotación de edición.',
  assigningKeyToMember: 'Asignación de clave a miembro.',
  revokingKeyFromMember: 'Revocación de la clave de miembro.',
  deletingTeam: 'Borrar equipo.',
  deletingRotation: 'Borrar rotación.',
  addingAlert: 'Añadir oyente.',
  editingAlert: 'Editar oyente.',
  deletingAlert: 'Borrar oyente.',
  triggeringAlert: 'Incidente desencadenante.',
  creatingOrganizationAccount: 'Crear cuenta de organización.',
  creatingCheckoutSession: 'Iniciar la sesión de pago.',
  creatingAnAccountForMember: 'Crear una cuenta para miembro.',
  passwordResetEmailSent:
    'Te hemos enviado un correo electrónico para restablecer tu contraseña. Cambia tu contraseña e inicia sesión de nuevo.',
  changeOwner: 'Cambiar propietario',
  changeOrganizationOwner: 'Cambiar el propietario de la organización',
  newOwnerEmail: 'Correo electrónico del nuevo propietario',
  saveOnYearlySubscription: 'Guardar {{percent}}%',
  annual: 'Anual',
  resetAlarms: 'Actualizar alarmas',
  resetAlarmsAlert:
    'Se han actualizado todas las alarmas. Si sigue observando problemas con las alarmas, envíe un ticket utilizando la opción "Informar de un problema".',
  resetAlarmsTooltipMessage:
    'Si tiene problemas con las alarmas, como alarmas borradas que llaman por teléfono, actualice las alarmas mediante esta opción. Si el problema persiste, envíe un ticket utilizando la opción "Informar de un problema".',
  resetTempPassword: 'Restablecer contraseña temporal',
  currPassword: 'Contraseña actual',
  tempPassword: 'Contraseña temporal',
  newPassword: 'Nueva contraseña',
  reset: 'Restablecer',
  loggingIn: 'Inicio de sesión',
  resettingTempPassword: 'Restablecer la contraseña temporal',
  changingPassword: 'Cambio de contraseña',
  numSeats: 'Número de plazas',
  helpMeDecide: 'Ayúdame a decidir',
  numEnterpriseSeatsHelper:
    'Debe elegir el número de puestos como el número de personas de su organización que utilizarán la aplicación para recibir incidencias. Al configurar un receptor, puede especificar su política de escalado. Puede tener múltiples niveles en una política de escalado. Por ejemplo, puede especificar que el primer nivel de escalado sea a un equipo de 3 personas. Si el primer nivel de escalado no responde en 5 minutos, el segundo nivel de escalado es a un equipo de 5 personas. En este caso, puede especificar que el número de puestos sea 8.',
  numBusinessSeatsHelper:
    'Elija el número de plazas que corresponda al número de personas que utilizarán Galarm para recibir alarmas y recordatorios.',
  perSeatPrice: '{{price}} por {{interval}} por asiento',
  doneForToday: 'Reconocido',
  getStarted: 'Comenzar',
  firstAlertInstructions: 'Instrucciones para el primer oyente',
  learnMoreAboutGalarmEnterprise: 'Más información sobre Galarm Pro',
  home: 'Inicio',
  team: 'Equipo',
  alert: 'Alerta',
  listener: 'Oyente',
  forgotPassword: '¿Ha olvidado su contraseña?',
  forgotPasswordTitle: 'Contraseña olvidada',
  sendPasswordResetEmail:
    'Enviar correo electrónico de restablecimiento de contraseña',
  sendingPasswordResetEmail:
    'Enviar correo electrónico de restablecimiento de contraseña',
  field: 'Campo',
  description: 'Descripción',
  requestIntegration: 'Solicitar otra integración',
  integrationDetails: 'Detalles de la integración',
  enterIntegrationDetails: 'Proporcionar detalles de la integración',
  galarmEnterpriseFreeTrial: 'Prueba gratuita de 14 días',
  galarmEnterpriseFreeTrialDescription: 'Automatizar la gestión de incidencias',
  bySigningUpYouAgreeToOur: 'Al registrarse, acepta nuestra',
  and: 'y',
  alarmSubscribers: 'Seguidores de alarma',
  otherSubscribersCount: {
    one: '1 seguidor más que no está en tus contactos',
    other: '{{count}} más seguidores que no están en tus contactos'
  },
  getAccountData: 'Exportar datos de alarma',
  getAccountDataPrompt:
    'Indique una dirección de correo electrónico a la que le enviaremos un archivo de texto con todas sus alarmas.',
  getAccountDataConfirmation:
    'Tenga en cuenta que le enviaremos por correo electrónico un archivo de texto con todas sus alarmas a {{email}}.',
  sendingAccountData: 'Exportar datos de alarma...',
  sentAccountData: 'Datos de alarma enviados a {{email}}.',
  errorSendingAccountData: 'Error al exportar datos de alarma: {{error}}',
  sendingEmailVerificationLink:
    'Enviar enlace de verificación por correo electrónico',
  viewLogs: 'Ver registros',
  message: 'Mensaje',
  billing: 'Facturación',
  newIncidentForAlert: 'Nuevo incidente para el oyente {{alertName}}',
  clickNextAfterAddingMembers:
    'Haga clic en "Siguiente" después de añadir miembros',
  clickDoneAfterAddingAlerts:
    'Haga clic en "Hecho" para completar la configuración e ir al Panel de control.',
  alertCreatedSuccessfully:
    'La escucha se ha creado correctamente. Puede activar esta escucha desde el icono de menú de tres puntos situado delante del nombre de la escucha.',
  fixSubscription: 'Suscripción fija',
  fixSubscriptionDescription:
    'Hemos detectado un problema con su suscripción. Su suscripción contiene {{numSeats}} plaza(s) pero ha asignado llaves a {{numSeatsAssigned}} miembro(s). Puede asignar como máximo {{numSeats}} llave(s).',
  fixSubscriptionChooseOption:
    'Por favor, arregle su suscripción utilizando una de las opciones siguientes.',
  fixSubscriptionContactAdmin:
    'Póngase en contacto con el administrador de su organización.',
  unassignKeysFromMembers:
    'Desasignar al menos {{extraKeysAssigned}} clave(s) de los miembros',
  addMoreSeatsToSubscription:
    'Añada al menos {{extraKeysAssigned}} asiento(s) a su abono',
  revokeMembersKey: 'Revocar la clave de miembro',
  revokeMembersKeyDescription:
    'Seleccione los miembros cuyas claves desea revocar.',
  revoke: 'Revocar',
  unableToRevokeMembersKey:
    'Se ha producido un error al revocar la clave de miembro. Póngase en contacto con el servicio de asistencia.',
  problemWithLoadingProducts: 'Problema con la carga de productos. {{error}}',
  verificationCompleted: 'Verificación completa',
  deviceAlreadyRegistered:
    'Este número de teléfono ha sido verificado previamente en este dispositivo.',
  phoneNumberAutoFilled:
    'Galarm utilizará el número de teléfono seleccionado de tu cuenta de Google.',
  unableToRegisterPreVerifiedUser:
    'No se ha podido registrar el usuario preverificado.',
  allowNotifications: 'Permitir notificaciones',
  allowNotificationsDescription: 'Para visualizar las alarmas cuando llaman.',
  scheduleExactAlarms: 'Programar alarmas',
  scheduleExactAlarmsHelperText:
    'Permite que Galarm llame a las alarmas a la hora exacta.',
  scheduleExactAlarmsDescription: 'Para llamar a las alarmas a la hora exacta.',
  disableBatteryOptimization: 'Gestionar la optimización de la batería',
  disableBatteryOptimizationDescription:
    'Para permitir que Galarm se ejecute en segundo plano.',
  requestRequiredPermissions:
    'Galarm requiere los siguientes permisos para llamar a las alarmas',
  allowDisallowedPermissions: 'Pulse para permitir',
  notificationsAreEnabled:
    'El permiso para mostrar notificaciones ya está permitido.',
  connectingToStore: 'Conectarse a la tienda...',
  restartApp: 'Reiniciar la aplicación',
  updateAvailable: 'Actualización disponible',
  english: 'Inglés',
  russian: 'Ruso',
  german: 'Alemán',
  french: 'Francés',
  spanish: 'Español',
  italian: 'Italiano',
  portuguese: 'Portugués',
  japanese: 'Japonés',
  korean: 'Coreano',
  indonesian: 'Indonesia',
  changeAppLanguage: 'Cambiar el idioma de la aplicación',
  appLanguage: 'Idioma de la aplicación',
  iosChangeAppLanguagePara1:
    'Galarm selecciona el idioma de la aplicación en los ajustes de tu teléfono. Sigue estos pasos para cambiar el idioma de la aplicación de Galarm:',
  iosChangeAppLanguageInstruction1:
    'Abra "Ajustes del teléfono > General > Idioma y región". Asegúrese de que el idioma que desea utilizar para Galarm está presente en la lista de idiomas preferidos.',
  iosChangeAppLanguageInstruction2:
    'Vuelve a "Ajustes del teléfono" y toca "Galarm" en la lista de aplicaciones de la parte inferior.',
  iosChangeAppLanguageInstruction3:
    'Pulse sobre "Idioma" y seleccione el idioma que desea ajustar para Galarm. Vuelve a la aplicación después de elegir el idioma.',
  androidChangeAppLanguagePara1:
    'Galarm selecciona el idioma de la aplicación en los ajustes de tu teléfono. Sigue estos pasos para cambiar el idioma de la aplicación de Galarm:',
  androidChangeAppLanguageInstruction1:
    'A continuación, pulse el botón "Abrir ajustes de la aplicación".',
  androidChangeAppLanguageInstruction2:
    'Pulse sobre "Idioma" y seleccione el idioma que desea utilizar para Galarm.',
  androidChangeAppLanguageInstruction3:
    'Después de elegir el idioma, vuelve aquí y haz clic en el botón "Reiniciar aplicación".',
  problemRetrievingSubscriptionData:
    'Se ha producido un problema al recuperar su información de suscripción. Le estamos redirigiendo a la página de la cuenta. Compruebe el estado de su suscripción y póngase en contacto con el servicio de asistencia técnica si sigue teniendo problemas.',
  deletingOrganization: 'Borrar organización...',
  deleteOrganization: 'Borrar organización',
  deleteOrganizationDescription:
    '¿Está seguro de que desea eliminar su organización?\n  \n  Se trata de una operación irreversible. Se eliminarán todos los datos de tu organización.\n  \n  Asegúrese de haber cancelado su suscripción antes de eliminar la organización.',
  galarmEnterpriseTrialDescription:
    'Hay un periodo de prueba gratuito de 14 días durante el cual puedes cancelar la suscripción sin coste alguno.',
  galarmEnterpriseTrialAlreadyAvailed:
    'Ya ha utilizado la prueba gratuita de 14 días. Ponte en contacto con el servicio de asistencia si necesitas más horas para evaluar el producto.',
  signinWithGoogle: 'Iniciar sesión con Google',
  signinWithGithub: 'Iniciar sesión con GitHub',
  verifyAccount: 'Verificar cuenta',
  verifyAccountMessage:
    'Debería haber recibido un correo electrónico con un código de verificación. Introduzca el código para verificar su cuenta. Si no ha recibido el código, puede solicitar uno nuevo haciendo clic en el botón "Reenviar código".',
  verificationCode: 'Código de verificación',
  optionalString: '{{string}}',
  quickReminderTitle: 'Título (opcional)',
  accountVerified: 'Cuenta verificada',
  accountVerifiedMessage: 'Inicie sesión con sus credenciales.',
  multipleUsersWithSameEmail:
    'Hay varios usuarios con el mismo correo electrónico. Póngase en contacto con el servicio de asistencia.',
  multipleUsersWithSameMobileNumber:
    'Hay varios usuarios con el mismo número de móvil. Póngase en contacto con el servicio de asistencia.',
  startTrial: 'Iniciar prueba',
  nextSteps: 'Pasos siguientes',
  firstTimeSetupComplete:
    'Usted ha completado la configuración inicial de Galarm Enterprise y ha creado su primer oyente. A continuación se detallan los siguientes pasos que puede seguir.',
  testTheAlertInstruction:
    'Consulta las instrucciones en {{link}} para saber cómo probar el listener que acabas de crear.',
  integrateTheAlertInstruction:
    'Consulte las instrucciones en {{link}} para integrar el listener con varias herramientas de monitorización.',
  createMoreAlertsInstruction:
    'Puedes añadir más oyentes accediendo a la pestaña "Oyentes" de la izquierda.',
  addMoreMembersInstruction:
    'Puede añadir más miembros accediendo a la pestaña "Miembros" de la izquierda.',
  createTeamsInstruction:
    'Puede agrupar a los miembros en "Equipos" y alertar a todo un equipo cuando se produzca un incidente. Cree equipos accediendo a la pestaña "Equipos" de la izquierda y utilícelos en la configuración del receptor.',
  testTheAlert: 'Probar la escucha',
  integrateTheAlert: 'Integrar la escucha',
  createMoreAlerts: 'Crear más oyentes',
  addMoreMembers: 'Añadir más miembros',
  createTeams: 'Crear equipos',
  docs: 'Docs',
  contactSupport: 'Contacto',
  pastDue: 'Vencidos',
  canceled: 'Cancelado',
  unpaid: 'Sin pagar',
  incomplete: 'Incompleto',
  incomplete_expired: 'Incompleto Caducado',
  trialing: 'Prueba gratuita',
  paused: 'En pausa',
  plan: 'Plan',
  status: 'Estado',
  renewsOn: 'Renueva el',
  yourRoles: 'Sus funciones',
  enterprisePlan: '{{quantity}} asientos, renueva cada {{interval}}',
  currentOrganization: 'Organización actual',
  useThisOrganization: 'Utilice esta organización',
  fixPayment: 'Pago fijo',
  fixingPayment: 'Fijación del pago',
  addPaymentInformation: 'Añadir información de pago',
  noPaymentInformationAvailable:
    'No hay información de pago disponible. Actualice la página si ha añadido recientemente información de pago.',
  openBillingPortal: 'Portal de facturación abierto',
  startupWizardDescription:
    'Este asistente te ayudará a empezar y a crear tu primer oyente.',
  userNotFound: 'Usuario no encontrado',
  wrongPassword: 'Contraseña incorrecta',
  emailAlreadyInUse: 'Correo electrónico ya en uso',
  restoreDeletedAlarms: 'Restaurar alarmas borradas',
  deleteAlarmAlert:
    'Las alarmas borradas pueden restaurarse desde "Alarmas borradas recientemente". Verlas pulsando el menú de tres puntos (⋮) en la parte superior derecha de la pestaña "Alarmas".\n\nLas alarmas borradas permanecen allí durante 30 días, después de los cuales se borran permanentemente.',
  slackVerificationCode:
    'Su código de verificación de Slack es {{verificationCode}}.',
  dndAccess: 'Acceso No Molestar',
  dndHelperText:
    'Permita que Galarm llame a las alarmas cuando su teléfono esté en No molestar.',
  cantRestoreInstantAlarmMessage:
    'Las alarmas instantáneas no se pueden restaurar. Ver chat y otra información de esta alarma si es necesario. Esta alarma se eliminará permanentemente transcurridos 30 días desde su eliminación.',
  upgradeToUnlockAllFeatures:
    'Actualización para desbloquear todas las funciones',
  otherSignupOptions: 'Otras opciones de inscripción:',
  renewSubscription: 'Renovar suscripción',
  subscriptionOnHold:
    'En espera (Su plan permite {{subscriptionQty}} asientos\npero ha asignado {{numSubscriptionKeys}} asientos.',
  checklists: 'Tareas',
  checklist: 'Lista de tareas',
  typeToAddTask: 'Tipo para añadir tarea',
  specifyTaskName: 'Especificar el nombre de la tarea',
  otherChecklists: 'Hojas de ruta',
  defaultChecklistName: 'sin título',
  addTasksToChecklist:
    'Añadir tareas utilizando el cuadro de entrada de la parte inferior',
  addTasksToDefaultChecklist:
    'Añada tareas utilizando el cuadro de entrada de la parte inferior... o cree una nueva lista de tareas haciendo clic en el botón + de la parte superior derecha.',
  createNewChecklist:
    'Puede crear nuevas listas de tareas para realizar un seguimiento de las tareas relacionadas en un solo lugar.',
  addNewChecklist: 'Nueva lista de tareas',
  alarmPhoto: 'Foto de alarma',
  alarmPhotoFeatureTitle: 'Foto de alarma',
  alarmPhotoFeatureDescriptionPremiumUser:
    'Con esta función, puede añadir una foto a una alarma para obtener indicaciones visuales rápidas, como la foto de: un medicamento, instrucciones para una tarea, un objeto, etc. La foto se mostrará cuando llame la alarma.',
  alarmPhotoFeatureDescriptionFreeUser:
    'Con esta función, puede añadir una foto a una alarma para obtener indicaciones visuales rápidas, como la foto de: un medicamento, instrucciones para una tarea, un objeto, etc. La foto se mostrará cuando llame la alarma.\n  \nSe trata de una función premium. Se le pedirá que actualice a premium para poder utilizar esta función.',
  alarmPhotoPremiumFeature: 'Añadir foto a las notas de alarma',
  installPendingAppUpdate:
    'La última actualización de la aplicación se ha descargado y está lista para ser instalada.',
  numTasks: { zero: 'Sin tareas', one: '1 tarea', other: '{{count}} tareas' },
  numTasksWithCompleted: {
    one: '1 tarea, {{completed}} completada',
    other: '{{count}} tareas, {{completed}} completadas'
  },
  muteAlarms: 'Silenciar alarmas',
  unmuteAlarms: 'Desactivar alarmas',
  alarmsAreMuted:
    'Todas las alarmas están silenciadas. Pulse para anular el silencio.',
  alarmsHaveBeenMuted:
    'Tus alarmas han sido silenciadas. No llamarán hasta que las desactive.',
  alarmsHaveBeenUnmuted:
    'Sus alarmas han sido desactivadas. Deberían llamar tal y como están configuradas.',
  deleteChecklist: 'Borrar lista de tareas',
  confirmDeleteChecklist: '¿Borrar la lista de tareas?',
  confirmDeleteCompletedTasks: '¿Borrar tareas completadas?',
  confirmResetCompletedTasks: '¿Restablecer tareas completadas?',
  confirmDeleteTask: 'Borrar {{taskName}}?',
  showCompletedTasks: 'Mostrar tareas completadas',
  hideCompletedTasks: 'Ocultar tareas completadas',
  deleteCompletedTasks: 'Borrar tareas completadas',
  resetCompletedTasks: 'Restablecer tareas completadas',
  yourGroups: 'Sus grupos',
  newAlarmWith: 'Nueva alarma con {{name}}',
  newGroupWith: 'Nuevo grupo con {{name}}',
  alertContact: 'Alerta {{name}}',
  alertGroup: 'Alerta {{name}}',
  instantAlarmMovedToFabNotice:
    'La función de alarma instantánea está ahora disponible desde el botón "+" situado en la parte inferior derecha de esta pantalla. El icono del "altavoz" desaparecerá de la parte superior derecha en una futura versión.',
  tasksIntroTitle: 'Tareas y hojas de ruta',
  tasksIntroDescription:
    'Las tareas son los asuntos pendientes que no tienen una fecha límite. Aquí puedes añadirlas y gestionarlas rápidamente, marcándolas a medida que realizas cada tarea.\n\nTambién puedes crear listas de tareas para agrupar tareas similares. Por ejemplo, puedes crear una lista de tareas para hacer la compra, otra para las tareas del trabajo, etc.',
  groupsMovedToContactsScreen:
    'Los "Grupos" se han fusionado en esta pestaña. Tus grupos aparecen en la parte superior en "Tus grupos". Puedes crear nuevos grupos pulsando el botón "+" de la parte inferior derecha.',
  loadingTasks: 'Cargando tareas...',
  tryPremium: 'Prueba Premium',
  buyPremiumToUnlockFeature:
    'Esta es una función premium. Desbloquea esta y muchas otras funciones interesantes con nuestro plan premium que incluye una prueba gratuita de 14 días.',
  newTask: 'Nueva tarea',
  newTaskList: 'Nueva lista de tareas',
  enterTaskName: 'Introduzca el nombre de la tarea',
  enterTaskListName: 'Introduzca el nombre de la hoja de ruta',
  taskName: 'Nombre de la tarea',
  taskListName: 'Nombre de la lista de tareas',
  checklistTasks: '{{name}}',
  editTaskListName: 'Editar el nombre de la hoja de ruta',
  editTask: 'Editar tarea',
  releaseNotes: 'Notas de la versión',
  currentRelease: '(actualmente instalado)',
  loadingReleaseNotes:
    'Cargando notas de la versión. Asegúrese de que está conectado a Internet.',
  id: 'ID',
  subscriptionData: 'Datos de suscripción',
  organizations: 'Organizaciones',
  showRotationCalendar: 'Calendario de rotación de espectáculos',
  rotationCalendar: 'Calendario de rotaciones',
  editShift: 'Editar Turno',
  startDateForShift: 'Fecha de inicio del turno',
  startTimeForShift: 'Hora de inicio del turno',
  endDateForShift: 'Fecha de finalización del turno',
  endTimeForShift: 'Hora de finalización del turno',
  editingShift: 'Turno de edición',
  addingShift: 'Añadir turno',
  products: 'Productos',
  prices: 'Planes',
  planType: 'Tipo de plan',
  business: 'Empresas',
  enterprise: 'Empresa',
  compare: 'Compara',
  apiSupport: 'Soporte API para supervisar servicios',
  accessToConsoleToViewAndCloseIncidents:
    'Cuadro de mandos para gestionar las incidencias',
  triggerInstantAlertsFromTheConsole:
    'Activar incidentes desde la consola de empresa',
  coreFeatures: 'Características principales:',
  everythingInBusiness: 'Todo en Plan de empresa y:',
  updatePlanInstructions:
    'Puede actualizar su plan haciendo clic en el botón "Abrir portal de facturación" a la derecha y eligiendo el botón "Actualizar plan" en el portal de facturación.',
  membersDescription:
    'Usted ha comprado {{subscriptionKeysCount}} asientos -- {{assignedSubscriptionKeysCount}} asignados y {{unassignedSubscriptionKeysCount}} disponibles. Una marca de verificación delante del nombre de un miembro indica un asiento asignado.',
  centralBilling: 'Facturación central',
  saveTwoMonths: 'Guardar 2 meses',
  owner: 'Propietario',
  enterpriseAccountSummary:
    'Usted forma parte de la organización {{organizationName}} inscrita en el plan Galarm Pro {{planType}}.',
  perYearPerUser: 'por usuario/año',
  perMonthPerUser: 'por usuario/mes',
  creationDate: 'Fecha de creación',
  updatePhoneNumber: 'Cambiar el número de teléfono',
  confirmCountryCodeAndEnterUpdatedPhoneNumber:
    'Confirme el código de país e introduzca su nuevo número de teléfono. Su cuenta se vinculará a este número de teléfono una vez finalizada la verificación.',
  unableToUpdatePhoneNumber:
    'No se puede cambiar el número de teléfono: {{error}}. Póngase en contacto con el servicio de asistencia.',
  phoneNumberUpdated: 'Número de teléfono cambiado con éxito.',
  phoneNumberUpdatedMessage:
    'Puedes empezar a utilizar la aplicación con normalidad. Pide a tus contactos que actualicen tu número de teléfono en su agenda.',
  memberChangedPhoneNumber:
    '{{memberName}} ha cambiado de número de teléfono. Actualiza tu libreta de direcciones para seguir añadiéndolos a tus alarmas.',
  preferredSourceForIncidents: 'Fuente preferida para las incidencias',
  apiCodesDescription:
    'Utilice las siguientes instrucciones para integrar el oyente con una fuente',
  rotation: 'Rotación',
  noAlarmsForThisDay: 'No hay alarmas para este día',
  markAlarmsAsComplete: 'Marcar alarmas como completas',
  markAlarmsAsCompleteExplanation:
    'Las alarmas de una hora se marcarán como completas y aparecerán en la sección de alarmas "Inactivas".\n\nLas alarmas que se repiten se marcarán como completas y se trasladarán a la siguiente ocurrencia.',
  enterIncidentPrefix: 'Máximo 5 caracteres',
  incidentPrefix: 'Prefijo del incidente',
  incidentPrefixAlreadyExists: 'El prefijo del incidente ya existe',
  removeTask: '¿Eliminar tarea?',
  incidentPrefixLengthExceeded:
    'El prefijo del incidente tiene más de 5 caracteres',
  criticalAlerts: 'Alertas críticas',
  criticalAlertsConfiguration:
    'La alarma llamará a un volumen de {{criticalAlertsVolume}}% aunque el teléfono esté silenciado o haya un Foco encendido.',
  criticalAlertsDisabledShort:
    'La alarma no llamará cuando el teléfono esté silenciado.',
  criticalAlertsConfigurationGlobal:
    'Las alarmas llamarán a un volumen de {{criticalAlertsVolume}}% aunque el teléfono esté silenciado o haya un Foco encendido.',
  criticalAlertsDisabledShortGlobal:
    'Las alarmas no llamarán cuando el teléfono esté silenciado.',
  criticalAlertsEnabled:
    'Las Alertas Críticas están activadas. Las alarmas llamarán aunque el teléfono esté silenciado o haya un Foco activado.',
  criticalAlertsDisabled:
    'Las Alertas Críticas están desactivadas. Las alarmas no llamarán cuando el teléfono esté silenciado.',
  enableCriticalAlerts: 'Activar alertas críticas',
  summer_soft: 'Verano suave',
  beeps: 'Pitidos',
  bollywood_nights: 'Noches de Bollywood',
  gentle_reminder: 'Recordatorio amable',
  himalayan_piper: 'Gaitero del Himalaya',
  holiday_joy: 'Alegría navideña',
  hollywood_adventure: 'Aventura en Hollywood',
  melody: 'Melody',
  morning_rooster: 'Gallo de la mañana',
  morning_sunshine: 'Sol matutino',
  sweet_sea: 'Mar Dulce',
  old_school_ring: 'Tring Tring',
  MyAlarmNotification: '{{alarmName}} - Es la hora.',
  ParticipantCascadingAlarmNotification:
    '{{alarmName}} - Es hora de recordatorios {{alarmCreatorName}}.',
  ParticipantSimultaneousAlarmNotification:
    '{{alarmName}}. Es hora de confirmar su participación.',
  InstantAlarmNotification: '{{alarmName}}. Se solicita su atención inmediata.',
  MyAlarmNotificationShort: 'Es la hora.',
  ParticipantCascadingAlarmNotificationShort:
    'Es hora de recordatorios {{alarmCreatorName}}.',
  ParticipantSimultaneousAlarmNotificationShort:
    'Es hora de confirmar su participación.',
  InstantAlarmNotificationShort: 'Se solicita su atención inmediata.',
  MyRecipientAlarmNotification:
    '{{alarmName}} - Es hora de recordatorios {{alarmRecipientName}}.',
  MyRecipientAlarmNotificationShort:
    'Es hora de recordatorios {{alarmRecipientName}}.',
  RecipientAlarmNotification:
    '{{alarmName}} (por {{alarmCreatorName}}) - Es la hora.',
  RecipientAlarmNotificationShort: 'Es la hora - por {{alarmCreatorName}}.'
}
