import React, { useEffect, useState } from 'react'
import { Box, DataTable, Text, Pagination } from 'grommet'
import { I18n } from 'galarm-config'
import { collection, doc, getDoc, onSnapshot } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'
import SearchBar from './SearchBar'
import GlobalConfig from '../GlobalConfig'
import { DateTimeUtils } from 'galarm-shared'

const ITEMS_PER_PAGE = 10 // Number of items to display per page

const Organizations = () => {
  const navigate = useNavigate()

  const [organizations, setOrganizations] = useState([])
  const [orgOwners, setOrgOwners] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    // Get enterpriseAccounts from Firestore
    const unsubscribe = onSnapshot(
      collection(GlobalConfig.firestoreDb, 'enterpriseAccounts'),
      snapshot => {
        const enterpriseAccounts = snapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .filter(enterpriseAccount => enterpriseAccount.state !== 'inactive')
        setOrganizations(enterpriseAccounts)
      }
    )
    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    const loadOrgOwners = async () => {
      let localOrgOwners = []
      let promises = []

      organizations.forEach(org => {
        if (org.owner) {
          promises.push(
            getDoc(doc(GlobalConfig.firestoreDb, 'enterpriseUsers', org.owner))
          )
        }
      })

      const enterpriseUserDocs = await Promise.all(promises)
      enterpriseUserDocs.forEach(doc => {
        if (doc.exists()) {
          const enterpriseUser = doc.data()
          localOrgOwners.push({
            id: enterpriseUser.id,
            email: enterpriseUser.email
          })
        }
      })

      setOrgOwners(localOrgOwners)
    }
    loadOrgOwners()
  }, [organizations])

  const onPageChange = ({ page, startIndex, endIndex }) => {
    console.log('page', page, 'startIndex', startIndex, 'endIndex', endIndex)
    setCurrentPage(page)
  }

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
  const endIndex = startIndex + ITEMS_PER_PAGE

  const filteredOrganizations = organizations.filter(org =>
    org.name.toLowerCase().includes(searchText.toLowerCase())
  )

  const slicedData = filteredOrganizations
    .sort((a, b) => {
      if (a.creationDate && b.creationDate)
        return a.creationDate < b.creationDate ? 1 : -1
      else if (a.creationDate) return -1
      else if (b.creationDate) return 1
      else return 0
    })
    .slice(startIndex, endIndex)
    .map(org => ({
      id: org.id,
      name: org.name,
      numSeats: org.subscriptionData?.quantity || 'NA',
      status: org.subscriptionData?.status || 'NA',
      ownerEmail:
        orgOwners.find(owner => owner.id === org.owner)?.email || 'NA',
      creationDate: org.creationDate
        ? DateTimeUtils.getDateTimeAsString(org.creationDate)
        : 'NA',
      accountType: org.subscriptionData?.planType || 'NA'
    }))

  const columns = [
    {
      property: 'name',
      header: <Text>{I18n.t('name')}</Text>,
      render: datum => (
        <Text weight={500} onClick={() => navigate(datum.id)}>
          {datum.name}
        </Text>
      )
    },
    { property: 'creationDate', header: <Text>{I18n.t('creationDate')}</Text> },
    { property: 'ownerEmail', header: <Text>{I18n.t('owner')}</Text> },
    { property: 'accountType', header: <Text>{I18n.t('accountType')}</Text> },
    {
      property: 'numSeats',
      header: <Text>{I18n.t('numSeats')}</Text>,
      align: 'center'
    },
    { property: 'status', header: <Text>{I18n.t('status')}</Text> }
    // Add more columns as needed
  ]

  return (
    <Box align="center" pad="large" gap="small">
      <SearchBar onChangeText={setSearchText} searchText={searchText} />
      <Box overflow={{ horizontal: 'auto' }}>
        <DataTable
          columns={columns}
          data={slicedData}
          background={{
            header: 'light-4',
            body: ['white', 'light-2'],
            footer: { dark: 'light-2', light: 'dark-3' }
          }}
          border
        />
      </Box>
      <Pagination
        numberItems={filteredOrganizations.length}
        page={currentPage}
        itemsPerPage={ITEMS_PER_PAGE}
        onChange={onPageChange}
      />
    </Box>
  )
}

export default Organizations
