import { doc, getDoc } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { I18n, Constants } from 'galarm-config'
import { Card, CardBody, Heading, Box, Text, Button } from 'grommet'
import React from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom'
import GlobalConfig from '../GlobalConfig'

export const loader = async ({ params }) => {
  console.log('Organization loader params', params)
  const orgId = params.orgId

  return getDoc(doc(GlobalConfig.firestoreDb, 'enterpriseAccounts', orgId))
    .then(doc => {
      if (doc.exists()) {
        return doc.data()
      } else {
        throw new Error('Organization account does not exist')
      }
    })
    .catch(error => {
      throw new Error('Error fetching organization account. ' + error.message)
    })
}

const Organization = () => {
  const enterpriseAccount = useLoaderData()
  const navigate = useNavigate()

  const onDeleteOrganization = async () => {
    GlobalConfig.showAlert(
      I18n.t('deleteOrganization'),
      I18n.t('deleteOrganizationDescription'),
      [
        {
          text: I18n.t('cancel')
        },
        {
          text: I18n.t('delete'),
          onPress: () => {
            deleteOrganizationCore(enterpriseAccount.id)
          }
        }
      ]
    )
  }

  const deleteOrganizationCore = async enterpriseAccountIdToBeDeleted => {
    console.log('deleteOrganization', enterpriseAccountIdToBeDeleted)
    GlobalConfig.showProgress({
      state: Constants.ProgressStates.IN_PROGRESS,
      message: I18n.t('deletingOrganization'),
      closeable: false
    })
    try {
      const functions = getFunctions()
      const deleteOrganization = httpsCallable(functions, 'deleteOrganization')
      await deleteOrganization({
        enterpriseAccountId: enterpriseAccountIdToBeDeleted
      })
      GlobalConfig.hideProgress()
      navigate(-1)
    } catch (error) {
      GlobalConfig.hideProgress()
      console.error(error)
    }
  }

  const onFixPayment = async () => {
    const subscriptionData = enterpriseAccount.subscriptionData
    if (!subscriptionData || !subscriptionData.customerId) {
      GlobalConfig.showAlert(
        I18n.t('fixPayment'),
        I18n.t('noSubscriptionData'),
        [{ text: I18n.t('ok') }]
      )
      return
    }

    GlobalConfig.showProgress({
      state: Constants.ProgressStates.IN_PROGRESS,
      message: I18n.t('fixingPayment'),
      closeable: false
    })

    const customerId = subscriptionData.customerId
    try {
      const functions = getFunctions()
      const syncSubscriptionsForCustomer = httpsCallable(
        functions,
        'syncSubscriptionsForCustomer'
      )
      await syncSubscriptionsForCustomer({
        enterpriseAccountId: enterpriseAccount.id,
        customerId: customerId
      })
      GlobalConfig.hideProgress()
    } catch (error) {
      GlobalConfig.hideProgress()
      console.error(error)
    }
  }

  console.log('enterpriseAccount', enterpriseAccount)
  return (
    <Box margin="medium">
      <Card width="large" background="textBackgroundColor">
        <CardBody pad="medium" overflow="auto" gap="xsmall">
          <Heading level={4} margin="none">
            {enterpriseAccount.name}
          </Heading>
          <Box>
            <Text size="small" weight={'bold'}>
              {I18n.t('id') + ':'}
            </Text>
            <Text size="small">{enterpriseAccount.id}</Text>
          </Box>
          <Box>
            <Text size="small" weight={'bold'}>
              {I18n.t('subscriptionData') + ':'}
            </Text>
            <Text size="small" style={{ whiteSpace: 'pre-line' }}>
              {JSON.stringify(enterpriseAccount.subscriptionData, null, 2)}
            </Text>
          </Box>
          <Box direction="row" gap="small" margin={{ vertical: 'small' }}>
            <Button label={I18n.t('delete')} onClick={onDeleteOrganization} />
            <Button label={I18n.t('fixPayment')} onClick={onFixPayment} />
          </Box>
        </CardBody>
      </Card>
    </Box>
  )
}

export default Organization
